import React from "react";
import Navbar from "../admin-components/AdminNavbar";
import Upgrade from "../admin-components/UpgradeContent";
import classes from "./admin.module.css";

const Admin = () => {
  return (
    <>
      <section className={classes.section}>
        <Navbar activeLink={"Upgrade Request"} />
        <Upgrade />
      </section>
    </>
  );
};

export default Admin;