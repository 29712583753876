import classes from './Destinations.module.css'

export function Destinations() {

  return (
    <section className={classes.mainContainer}>
      <div className={classes.title}>
      <h1>Top Destinations</h1>
      <p>Most popular choices for travellers from Nigeria.</p>
      </div>
          <section className={classes.gridContainer}>
            <div className={classes.lagos}>
              <a href='/' className={classes.link}>Lagos
              <div className={classes.line}></div></a>
            </div>
            <div className={classes.abuja}>
              <a href='/' className={classes.link}>Abuja
              <div className={classes.line}></div></a>
            </div>
            <div className={classes.ph}>
              <a href='/' className={classes.link}>Port-Harcourt
              <div className={classes.line}></div></a>
            </div>
          </section>

          <section className={classes.gridContainer}>
            
            <div className={classes.benin}>
              <a href='/' className={classes.link}>benin
              <div className={classes.line}></div></a>
            </div>
            <div className={classes.ibadan}>
              <a href='/' className={classes.link}>Ibadan
              <div className={classes.line}></div></a>
            </div>
            <div className={classes.calabar}>
              <a href='/' className={classes.link}>calabar
              <div className={classes.line}></div></a>
            </div>
          </section>
    </section>
  );
}