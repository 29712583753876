import React, { useState, useEffect } from "react";
import {
  IconEdit,
  IconTrash,
  IconArrowBack,
  IconPlus,
} from "@tabler/icons-react";
import {
  SimpleGrid,
  Divider,
  Text,
  Image,
  Group,
  Button,
  Flex,
} from "@mantine/core";
import classes from "./rooms.module.css";
import {
  useGetHotelRoomsMutation,
  useDeleteRoomMutation,
} from "../slices/usersApiSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

const RoomsContent = () => {
  const [total, setTotal] = useState(0);
  const [rooms] = useGetHotelRoomsMutation();
  const [deleteRoom] = useDeleteRoomMutation();
  const [data, setData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const getHotelRooms = async () => {
    try {
      const response = await rooms(id).unwrap();
      setTotal(response.total);
      const room = response.data.map((item) => {
        const {
          title,
          no_of_rooms,
          no_of_beds,
          room_size,
          price,
          features,
          gallary,
          _id,
        } = item;
        return (
          <div className={classes.card} key={_id}>
            <div className={classes.item}>
              <div className={classes.imgContainer}>
                <a href="/">
                  <Image
                    h={220}
                    
                    // fit="contain"
                    src={gallary[0]}
                    className={classes.img}
                  />
                </a>
              </div>
              <div>
                <h4>{title}</h4>
                <Text size="sm" className={classes.textContainer}>
                  <span className={classes.text}>Bed:</span>
                  <span>{no_of_beds}</span>
                </Text>
                <Text size="sm" className={classes.textContainer}>
                  <span className={classes.text}>Room:</span>
                  <span>{no_of_rooms}</span>
                </Text>
                <Text size="sm" className={classes.textContainer}>
                  <span className={classes.text}>Room Size:</span>
                  <span>{room_size}</span>
                </Text>
                <Text size="sm" className={classes.textContainer}>
                  <span className={classes.text}>Price:</span>
                  <span className={classes.value}>{price}</span>
                </Text>
                <Text size="sm" className={classes.textContainer}>
                  <span className={classes.text}>Features:</span>
                  <span>
                    {features.map((feature, index) => (
                      <span key={index}>
                        {feature}
                        {index < features.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </span>
                </Text>
              </div>
            </div>
            <Group justify="center" p={15}>
              <Button
                size="xs"
                color="green"
                leftSection={<IconEdit size={14} />}
                variant="filled"
                onClick={() => navigate(`/hotel/room/edit/${_id}`)}
              >
                Edit
              </Button>
              <Button
                size="xs"
                color="red"
                leftSection={<IconTrash size={14} />}
                variant="filled"
                onClick={() => deleteHotel(_id)}
              >
                Delete
              </Button>
            </Group>
          </div>
        );
      });
      setData(room);
      return room;
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };
  const deleteHotel = async (id) => {
    try {
      const response = await deleteRoom(id).unwrap();
      getHotelRooms();
      toast.success(response.message);
      return response;
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  useEffect(() => {
    getHotelRooms();
    // eslint-disable-next-line
  }, []);

  return (
    <section className={classes.section}>
      <ToastContainer />
      <Flex
        justify={{ sm: "space-between" }}
        align="center"
        px={15}
        direction={{ base: "column", sm: "row" }}
      >
        <h2 className={classes.heading}>Manage Rooms: Royal Hotel</h2>
        <Group justify="flex-start">
          <Button
            leftSection={<IconPlus size={14} />}
            variant="filled"
            color="rgba(143, 189, 79, 1)"
            onClick={() => navigate(`/hotel/room/create/${id}`)}
          >
            Add Room
          </Button>
          <Button
            leftSection={<IconArrowBack size={14} />}
            variant="filled"
            color="cyan"
            onClick={() => navigate(`/hotel/edit/${id}`)}
          >
            Back to hotels
          </Button>
        </Group>
      </Flex>
      <Divider />
      <Text>
        Showing result for <span>{total}</span> hotels
      </Text>

      <SimpleGrid
        cols={{ base: 1, sm: 1, lg: 1 }}
        spacing={{ base: 10, sm: "xl" }}
        verticalSpacing={{ base: "md", sm: "xl" }}
      >
        {data}
      </SimpleGrid>
    </section>
  );
};

export { RoomsContent };
