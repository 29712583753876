
import Navbar from "../features/header/Navbar";
import { Searchbar } from "../components/Searchbar";
import { LocationContent } from "../components/LocationContent";


const Location = () => {

  return (
    <>
      <Navbar />
      <Searchbar />
      <LocationContent />
    </>
  );
};

export default Location;
