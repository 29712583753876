import React, { useState, useEffect } from "react";
import { Text, Group, Card, SimpleGrid, Loader } from "@mantine/core";
import "../components/HotelGallery.css";
import classes from "../components/Carousel.module.css";
import mainclasses from "./location.module.css";

import { useGetHotelsByLocationMutation } from "../slices/usersApiSlice";
import { useParams } from "react-router-dom";
export function HotelLocationContent() {
  const [hotels, { isLoading }] = useGetHotelsByLocationMutation();
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const { location } = useParams();


  const getHotels = async (page) => {
    try {
      const response = await hotels(location).unwrap();
        if (response.length === 0) {
          
        return "No hotels was found";
      }
      //   setTotal(response.total);
      const hotel = response.hotels.map((item) => {
        const { banner, address, title, price, _id } = item.item;
        return (
          <Card withBorder radius="md" className={classes.card} key={_id}>
            <Card.Section>
              <a href="/">
                <img className="Firsthotel" src={banner} alt="Firsthotel" />
              </a>
            </Card.Section>

            <a href="/hotel" className={classes.title} fw={500}>
              {title}
            </a>

            <Text fz="sm" className={classes.address}>
              {address}
            </Text>
            <Group justify="space-between" className={classes.footer}>
              <Text inline className={classes.price}>
                starting from <span>#{price}</span>
              </Text>
            </Group>
          </Card>
        );
      });
      setData(hotel);
      return hotel;
    } catch (error) {
      setData([])
      setError("Oops, this location isn't available. Please try another one");
      console.log(error);
    }
  };

  useEffect(() => {
    getHotels();
    // eslint-disable-next-line
  }, [location]);
  return (
    <section className={mainclasses.mainContainer}>
      <div className={mainclasses.title}>
        <h1>Properties at {location}</h1>
        <p>
          Find your dream vacation in the heart of <strong>{location}</strong>{" "}
          or discover our wide range of apartments and hotels for book. Browse
          through our properties by location
        </p>
      </div>
      <div className={mainclasses.gridContainer}>
        {isLoading ? (
          <Loader color="blue" />
        ) : data.length === 0 ? (
          <p className={classes.error}>{error}</p>
        ) : (
          <SimpleGrid
            cols={{ base: 1, sm: 2, lg: 3 }}
            spacing={{ base: 10, sm: "xl" }}
            verticalSpacing={{ base: "md", sm: "xl" }}
            className={mainclasses.section}
          >
            {data}
          </SimpleGrid>
        )}
      </div>
    </section>
  );
}