import { useEffect, useState } from "react";
import classes from "./location.module.css";
import { Button, Loader } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { useGetInterestMutation } from "../slices/usersApiSlice";
export function LocationContent(props) {
  const { interest: encodedLocation = "" } = useParams();
  const [get_interest, {isLoading}] = useGetInterestMutation();
  const [data, setData] = useState([])
  const navigate = useNavigate()

  const getLocations = async (interestArr) => {
    const response = await get_interest({ interest: interestArr }).unwrap();
    const data = response.map((item) => {
      const { locationImage, location, features, _id } = item
      return (
        <div className={classes.item} key={_id}>
          <div className={classes.contentContainer}>
            <div className={classes.img}>
              <img src={locationImage} alt={location} />
            </div>
            <div className={classes.content}>
              <h2>{location}</h2>
              <div>
                This location is equiped with features like{" "}
                <strong>
                  {" "}
                  {features.map((feature, index) => (
                    <span key={index}>
                      {feature}
                      {index < features.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </strong>
              </div>
            </div>
          </div>{" "}
          <Button variant="filled" className={classes.btn} onClick={()=> navigate(`/hotel/${location}`)}>
            Check properties
          </Button>
        </div>
      );
    })
    setData(data)
  };

  useEffect(() => {
    if (encodedLocation) {
      try {
        const decodedLocation = encodedLocation.split(",");
        getLocations(decodedLocation);
      } catch (error) {
        getLocations([]);
      }
    } else {
      getLocations([]);
    }
    // eslint-disable-next-line
  }, [encodedLocation]);

  return (
    <section className={classes.mainContainer}>
      <div className={classes.title}>
        <h1>Top Countries</h1>
        <p>
          Based on your search, we provide to you these locations that best suit
          your taste, click to view hotels available for the location.
        </p>
      </div>
      <section className={classes.gridContainer}>
       {isLoading ? <Loader color="blue" /> : data}
      </section>
    </section>
  );
}
