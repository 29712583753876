import React from 'react'
import Navbar from '../components/VendorNavbar'
import {NewRoomContent} from '../components/NewroomContent'
import classes from './user.module.css'
const NewRoom = () => {
  return (
    <>
    <section className={classes.section}>
        <Navbar activeLink={''}/>
        <NewRoomContent/>
    </section>
    </>
  )
}

export default NewRoom
