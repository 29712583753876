import React, { useState, useEffect } from "react";
import { Text, Group, Card, SimpleGrid, Loader, Pagination } from "@mantine/core";
import "../components/HotelGallery.css";
import classes from "../components/Carousel.module.css";
import mainclasses from "./location.module.css";

import { useGetAllHotelsMutation } from "../slices/usersApiSlice";
export function LocationContent() {
//   const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hotels, { isLoading }] = useGetAllHotelsMutation();
  const [data, setData] = useState([]);
  let nPage;
  // const limit = 10

  const getHotels = async (page) => {
    try {
      const response = await hotels(page).unwrap();
      if (response.length === 0) {
        return "No hotels was found";
      }
    //   setTotal(response.total);
      const hotel = response.data.map((item) => {
        const { banner, address, title, price, _id } = item;
        return (
          <Card withBorder radius="md" className={classes.card} key={_id}>
            <Card.Section>
              <a href="/">
                <img className="Firsthotel" src={banner} alt="Firsthotel" />
              </a>
            </Card.Section>

            <a href="/hotel" className={classes.title} fw={500}>
              {title}
            </a>

            <Text fz="sm" className={classes.address}>
              {address}
            </Text>
            <Group justify="space-between" className={classes.footer}>
              <Text inline className={classes.price}>
                starting from <span>#{price}</span>
              </Text>
            </Group>
          </Card>
        );
      });
      setData(hotel);
      const nPages = Math.ceil(response.totalHotels / 4);
      setTotalPages(nPages);
      return hotel;
    } catch (error) {
      // toast.error(error?.data?.message || error.error)
      console.log(error);
    }
  };

  useEffect(() => {
    getHotels(nPage);
    // eslint-disable-next-line
  }, []);
  const setPagination = (value) => {
    nPage = value;
    getHotels(nPage);
  };
  return (
    <section className={mainclasses.mainContainer}>
      <div className={mainclasses.title}>
        <h1>Properties at your location of interest</h1>
        <p>
          Based on your search, we provide to you these properties that best suit
          your taste, click to view hotels available for the location.
        </p>
      </div>
      <div className={mainclasses.gridContainer}>
        <SimpleGrid
          cols={{ base: 1, sm: 2, lg: 3 }}
          spacing={{ base: 10, sm: "xl" }}
          verticalSpacing={{ base: "md", sm: "xl" }}
          className={mainclasses.section}
        >
          {isLoading ? <Loader color="blue" /> : data}
        </SimpleGrid>
        <Pagination total={totalPages} onChange={setPagination} />
      </div>
    </section>
  );
}
