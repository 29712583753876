import { useState } from 'react';
import { Group, Code, Drawer, rem, Divider, ScrollArea, Burger } from '@mantine/core';
import {
  IconDashboard,
  IconFingerprint,
  IconKey,
  IconHome2,
  IconHomePlus,
  IconHotelService,
  IconUser,
  IconSwitchHorizontal,
  IconLogout,
} from '@tabler/icons-react';
import { MantineLogo } from '@mantine/ds';
import classes from './userNavbar.module.css';
import { useDisclosure } from '@mantine/hooks';
import { useVendorRequestMutation } from "../slices/usersApiSlice";
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
  import { clearCredentials } from "../slices/authSlice";


const data = [
  { link: '', label: 'Dashboard', icon: IconDashboard, access: 'public' },
  { link: '/user', label: 'My Profile', icon: IconUser, access: 'public' },
  { link: '/hotel/hotels', label: 'My Hotels', icon: IconHotelService, access: 'private' },
  { link: '/hotel/create', label: 'Add Hotel', icon: IconHomePlus, access: 'private' },
  { link: '', label: 'Booking history', icon: IconFingerprint, access: 'public' },
  { link: '', label: 'Wishlist', icon: IconKey, access: 'public' },
  { link: '/', label: 'Home page', icon: IconHome2, access: 'public' },
];

export default function HotelNavbar({activeLink}) {
  const {userInfo} = useSelector((state)=>state.auth)
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const [vendorRequest, { isLoading }] = useVendorRequestMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let filteredTabs;

  const handleRequestVendor = async()=>{
    try {
      const response = await vendorRequest().unwrap()
      toast.success(response.msg)
    } catch (error) {
      toast.error(error?.data?.message || error.error) 
    }
  }

   const handleLogout = async (e) => {
     e.preventDefault();
     try {
       dispatch(clearCredentials());
       navigate("/");
     } catch (error) {
       console.error(error?.data?.message || error.error);
     }
   };

  const [active, setActive] = useState(activeLink);

  if (userInfo.role === 'admin' || userInfo.role === 'vendor') {
    filteredTabs = data
  }else{
    filteredTabs = data.filter((tab)=> tab.access === 'public')
  }

  const links = filteredTabs.map((item) => (
   
    <Link
      className={classes.link}
      data-active={item.label === active || undefined}
      to={item.link}
      key={item.label}
      onClick={(event) => {
        setActive(item.label);
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </Link>
  ));

  return (
    <>
      <nav className={classes.navbar}>
        <ToastContainer />
        <Group visibleFrom="sm">
          <div className={classes.navbarMain}>
            <Group className={classes.header} justify="space-between">
              <MantineLogo size={28} inverted style={{ color: "white" }} />
              <Code fw={700} className={classes.version}>
                v3.1.2
              </Code>
            </Group>
            {/* <button className={classes.vendor} onClick={()=>handleRequestVendor()}>{isLoading ? 'Loading...' : 'Request vendor'}</button> */}
            {userInfo.role === "admin" || userInfo.role === "vendor" ? (
              ""
            ) : (
              <button
                className={classes.vendor}
                onClick={() => handleRequestVendor()}
              >
                {isLoading ? "Loading..." : "Request vendor"}
              </button>
            )}
            {links}
          </div>

          <div className={classes.footer}>
            {userInfo.role === "admin" ? (
              <Link to="/admin" className={classes.link}>
                <IconSwitchHorizontal
                  className={classes.linkIcon}
                  stroke={1.5}
                />
                <span>Admin Dashboard</span>
              </Link>
            ) : (
              ""
            )}

            <Link
              component="button"
              className={classes.link}
              onClick={handleLogout}
            >
              <IconLogout className={classes.linkIcon} stroke={1.5} />
              <span>Logout</span>
            </Link>
          </div>
        </Group>
      </nav>
      <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        hiddenFrom="sm"
        title="BOOKTRIPP"
        zIndex={1000000}
      >
        <ScrollArea
          h={`calc(100vh - ${rem(80)})`}
          mx="-md"
          className={classes.drawer}
        >
          <Divider my="sm" />
          <button
            className={classes.vendor}
            onClick={() => handleRequestVendor()}
          >
            {isLoading ? "Loading..." : "Request vendor"}
          </button>
          {links}
          <Divider my="sm" />

          <div className={classes.footer}>
            {userInfo.role === "admin"? (
              <Link to="/admin" className={classes.link}>
                <IconSwitchHorizontal
                  className={classes.linkIcon}
                  stroke={1.5}
                />
                <span>Admin Dashboard</span>
              </Link>
            ) : (
              ""
            )}

            <Link
              component="button"
              className={classes.link}
              onClick={handleLogout}
            >
              <IconLogout className={classes.linkIcon} stroke={1.5} />
              <span>Logout</span>
            </Link>
          </div>
        </ScrollArea>
      </Drawer>
    </>
  );
}