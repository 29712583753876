import { useState } from 'react';
// import { useDisclosure } from '@mantine/hooks';
import { Stepper, Button, Group, TextInput, Code, Checkbox, Flex, rem, Grid, NumberInput, Text, Image, SimpleGrid } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import {  IconCurrencyNaira, IconBed, IconUpload, IconDoor } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import classes from './createhotel.module.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCreateRoomMutation} from '../slices/usersApiSlice';
import { useNavigate, useParams } from 'react-router-dom';

export function NewRoomContent() {
  // const [single_hotel] = useGetHotelMutation()
  const [room, { isLoading }] = useCreateRoomMutation();
  // const [banner, {isLoading: isBannerLoading}] = useUpdatedHotelBannerMutation()
  const [active, setActive] = useState(0);
  const navigate = useNavigate()
  const {id} = useParams()
  const [gallary, setGallary] = useState([])

  //previews the banner image
  const previews = gallary.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        h={200}
        src={imageUrl}
        onLoad={() => URL.revokeObjectURL(imageUrl)}
        className={classes.preview}
      />
    );
  });


  const form = useForm({
    initialValues: {
      title: '',
      features: [],
      price: '',
      no_of_rooms: '',
      no_of_beds: '',
      room_size: '',
      gallary: gallary
    },

    validate: (values) => {
      if (active === 0) {
        return {
          title: values.title.trim().length < 3
            ? 'Room name must include at least 3 characters'
            : null
        };
      }

      //validation on third step
      if (active === 1) {
        return {
          price: values.price< 1
            ? 'Please include price'
            : null,
          no_of_beds: values.no_of_beds < 1
            ? 'Please include number of beds'
            : null,
          no_of_rooms: values.no_of_rooms < 1
            ? 'Please include number of rooms'
            : null,
          room_size: values.room_size < 1
            ? 'Please include room size'
            : null,
        };
      }

      return {};
    },
  });


  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 5 ? current + 1 : current;
    });

  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const handleCreateRoom = async(values)=>{
    try {
      // console.log(values)
      const formData = new FormData();
      formData.append('title', values.title)
      formData.append('room_size', values.room_size)
      formData.append('no_of_beds', values.no_of_beds)
      formData.append('no_of_rooms', values.no_of_rooms)
      formData.append('price', values.price)
      formData.append('id', id)

        // Append features individually
        values.features.forEach((feature, index) => {
          formData.append('features', feature);
        });
          // Append features individually
      values.gallary.forEach((gallary, index) => {
        formData.append('gallary', gallary);
      });

      const response = await room(formData);

        // Check for specific success conditions
    if (response.data?.status !== 201) {
      toast.error(response.error.data.message || response.error);
    } else {
      // Handle unexpected success response status
      navigate(`/hotel/room/${id}`)
    }
    } catch (error) {
      toast.error(error?.data?.message || error.error)
    }
  }

  const handleBannerDrop = (acceptedFiles) => {
    setGallary(acceptedFiles);
    console.log(acceptedFiles)
    form.setValues((prevValues) => ({ ...prevValues, gallary: acceptedFiles }));
  };

  return (
    <section className={classes.section}>
      <ToastContainer />
      <Flex
       justify={{ sm: 'space-between'}}
       align="center"
       px={15}
       direction={{ base: 'column', sm: 'row' }}>
      <h2 className={classes.heading}>Add new room</h2>
      <Group justify="flex-start">
      <Button leftSection={<IconDoor size={14} />} variant="filled" color= 'yellow' onClick={()=>navigate(`/hotel/room/${id}`)}>
        Manage Rooms
      </Button>
    </Group>
    </Flex>

      <form onSubmit={form.onSubmit((values) => handleCreateRoom(values))}>
        <div className={classes.grid}>

          <Stepper active={active}>
            {/* first step starts*/}
            <Stepper.Step label="First step" description="General Info">
              <TextInput label="What is the name of this room?" description="This is the name guests will see when they search for your hotel" {...form.getInputProps('title')} mt={20} required />
              <Checkbox.Group {...form.getInputProps('features')} mt={20} label='Features' description="These are features that are offered regardig this room">
                <Flex direction="row" gap="md" wrap="wrap" mt={10}>
                  <Checkbox value="Restaurant" label="Restaurant" />
                  <Checkbox value="Parking space" label="Parking space" />
                  <Checkbox value="Bar" label="Bar" />
                  <Checkbox value="Sauna" label="Sauna" />
                  <Checkbox value="Wake-up call" label="Wake-up call" />
                  <Checkbox value="Laundry services" label="Laundry services" />
                  <Checkbox value="Car hire" label="Car hire" />
                  <Checkbox value="Internet / WI-FI" label="Internet / WI-FI" />
                  <Checkbox value="Transport services" label="Transport services" />
                  <Checkbox value="Coffee and Tea" label="Coffee and Tea" />
                  <Checkbox value="Bicycle Hire" label="Bicycle Hire" />
                  <Checkbox value="Free Luggage deposite" label="Free Luggage deposite" />
                  <Checkbox value="Flat TV" label="Flat TV" />
                  <Checkbox value="Garden" label="Garden" />
                  <Checkbox value="Boats" label="Boats" />
                  <Checkbox value="Cruises" label="Cruises" />
                </Flex>
              </Checkbox.Group>
            </Stepper.Step>
            {/* first step ends*/}

            {/* second step starts*/}
            <Stepper.Step label="Second step" description="Pricing">
            <Grid mt={20}
            >
                <Grid.Col span={6}>
                  <NumberInput label="Price" description="Set price per night" clampBehavior="strict" prefix="₦" min={1} allowNegative={false} allowDecimal={false} thousandSeparator="," leftSection={<IconCurrencyNaira style={{ width: rem(18), height: rem(18) }} stroke={1.5} />} {...form.getInputProps('price')}/>
                  <NumberInput mt="md" label="Number of beds" description="How many beds are in this room?" clampBehavior="strict" min={1} allowNegative={false} allowDecimal={false} thousandSeparator="," leftSection={<IconBed style={{ width: rem(18), height: rem(18) }} stroke={1.5} />} {...form.getInputProps('no_of_beds')}/>
                </Grid.Col>
                <Grid.Col span={6}>
                  <NumberInput label="Quantity of rooms" description="How many rooms is available?" {...form.getInputProps('no_of_rooms')}/>
                  <NumberInput mt="md" label="Room size" description="Room size in square feet"  clampBehavior="strict" min={1} allowNegative={false} allowDecimal={false} thousandSeparator=","{...form.getInputProps('room_size')}/>
                </Grid.Col>
              </Grid>
            </Stepper.Step>
            {/* second step ends*/}

            {/* final step starts*/}
            <Stepper.Step label="Final step" description="Room Gallary">
              <Dropzone onDrop={handleBannerDrop} onReject={() => toast.error('Maximum file exceeded.')} maxSize={3 * 1024 ** 2} accept={IMAGE_MIME_TYPE} maxFiles={5} className={classes.dropzone} {...form.getInputProps('banner')}>
                <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none' }}>

                  <Dropzone.Accept>
                    <IconUpload style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }} stroke={1.5}/>
                  </Dropzone.Accept>
{/* 
                  <Dropzone.Reject>
                    <IconX style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }} stroke={1.5}/>
                  </Dropzone.Reject>

                  <Dropzone.Idle>
                    <IconPhoto style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }} stroke={1.5}/>
                  </Dropzone.Idle> */}

                  <div>
                    <Text size="xl" inline>
                      Room Gallary
                    </Text>
                    <Text size="sm" c="dimmed" inline mt={7}>
                      Drag and Drop or Click to upload. (max 5)
                    </Text>
                  </div>
                </Group>
              </Dropzone>

              <SimpleGrid cols={{ base: 1, sm: 4 }} mt={previews.length > 0 ? 'xl' : 0}>
                {previews}
              </SimpleGrid>
            </Stepper.Step>
            {/* final step ends*/}

            <Stepper.Completed>
              Completed! Form values:
              <Code block mt="xl">
                {JSON.stringify(form.values, null, 2)}
              </Code>
            </Stepper.Completed>
          </Stepper>
        </div>

        <Group justify="flex-end" mb={100}>
          {active !== 0 && (
            <Button variant="default" onClick={prevStep}>
              Back
            </Button>
          )}
          {active !== 3 && <Button onClick={nextStep}>Next step</Button>}
          {active === 3 && <Button type="submit" disabled={isLoading} leftSection={<IconUpload/>}>{isLoading ? 'Loading...' : 'Publish Room'}</Button>}
        </Group>
      </form>
    </section>
  );
}
