import React from "react";
import Navbar from "../features/header/Navbar";
import { Searchbar } from "../components/Searchbar";
import { HotelLocationContent } from "../components/HotelLocationContent";
import { ContactUs } from "../components/ContactUs";
import { FooterLinks } from "../components/FooterLinks";

const Hotel = () => {
  return (
    <>
      <Navbar />
      <Searchbar />
      <HotelLocationContent />
      <ContactUs />
      <FooterLinks />
    </>
  );
};

export default Hotel
