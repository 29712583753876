import { apiSlice } from "./apiSlice";
// const USER_URL = "http://localhost:5000/api/auth";
// const VENDOR_URL = "http://localhost:5000/api/vendor";
// const HOTEL_URL = "http://localhost:5000/api/hotel";
// const ROOM_URL = "http://localhost:5000/api/room";
// const LOCATION_URL = "http://localhost:5000/api/location";
const USER_URL = 'https://booktripp-backend.onrender.com/api/auth'
const VENDOR_URL = 'https://booktripp-backend.onrender.com/api/vendor'
const HOTEL_URL = 'https://booktripp-backend.onrender.com/api/hotel'
const ROOM_URL = 'https://booktripp-backend.onrender.com/api/room'
const LOCATION_URL = "https://booktripp-backend.onrender.com/api/location";


export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `${USER_URL}/login`,
        method: "POST",
        body: data,
        credentials: "include",
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: `${USER_URL}/register`,
        method: "POST",
        body: data,
        credentials: "include",
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${USER_URL}/logout`,
        method: "POST",
        credentials: "include",
      }),
    }),
    vendorRequest: builder.mutation({
      query: () => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${VENDOR_URL}`,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getUser: builder.mutation({
      query: () => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${USER_URL}/user`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    updateProfile: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${USER_URL}/`,
          method: "PATCH",
          body: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    addHotel: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${HOTEL_URL}`,
          method: "POST",
          body: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getOwnerHotels: builder.mutation({
      query: () => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${HOTEL_URL}/user`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getTop5Hotels: builder.mutation({
      query: () => ({
        url: `${HOTEL_URL}/top-five`,
        method: "GET",
        credentials: "include",
      }),
    }),
    getAllHotels: builder.mutation({
      query: (data) => ({
        url: `${HOTEL_URL}/hotels?page=${data}`,
        method: "GET",
        credentials: "include",
      }),
    }),
    deleteHotel: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${HOTEL_URL}/${data}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getHotel: builder.mutation({
      query: (data) => ({
        url: `${HOTEL_URL}/hotel/${data}`,
        method: "GET",
        credentials: "include",
      }),
    }),
    updatedHotel: builder.mutation({
      query: (data) => {
        const { id, ...dataWithoutId } = data; // Destructure the data object to separate the id
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${HOTEL_URL}/update/${id}`,
          method: "PATCH",
          body: dataWithoutId, // Use the data without the id in the body
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    updatedHotelBanner: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${HOTEL_URL}/update-banner`,
          method: "PATCH",
          body: data, // Use the data without the id in the body
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    createRoom: builder.mutation({
      query: (data) => {
        // const { id, ...dataWithoutId } = data; // Destructure the data object to separate the id
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${ROOM_URL}/`,
          method: "POST",
          body: data, // Use the data without the id in the body
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getHotelRooms: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${ROOM_URL}/${data}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getAHotelRooms: builder.mutation({
      query: (data) => {
        return {
          url: `${ROOM_URL}/rooms/${data}`,
          method: "GET",
        };
      },
    }),
    deleteRoom: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${ROOM_URL}/delete/${data}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getRoom: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${ROOM_URL}/room/${data}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    updateRoom: builder.mutation({
      query: (data) => {
        const { id, ...dataWithoutId } = data; // Destructure the data object to separate the id
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${ROOM_URL}/${id}`,
          method: "PATCH",
          body: dataWithoutId, // Use the data without the id in the body
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    updateRoomImages: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${ROOM_URL}/room/update-images/`,
          method: "PATCH",
          body: data, // Use the data without the id in the body
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getVendorRequests: builder.mutation({
      query: () => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${VENDOR_URL}/`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    approveVendorRequest: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${VENDOR_URL}/approve/${data}`,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    updateHotelGalleryImages: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${HOTEL_URL}/hotel/update-gallery/`,
          method: "PATCH",
          body: data, // Use the data without the id in the body
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getLocations: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${LOCATION_URL}?page=${data}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    createLocation: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${LOCATION_URL}`,
          method: "POST",
          body: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    deleteLocation: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${LOCATION_URL}/${data}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getLocation: builder.mutation({
      query: (data) => {
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${LOCATION_URL}/single/${data}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    updateLocation: builder.mutation({
      query: (data) => {
        // const { id, ...dataWithoutId } = data; Destructure the data object to separate the id
        const storedToken = localStorage.getItem("userInfo");
        const parsedToken = JSON.parse(storedToken);
        const { token } = parsedToken;
        return {
          url: `${LOCATION_URL}`,
          method: "PATCH",
          body: data, // Use the data without the id in the body
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getInterest: builder.mutation({
      query: (data) => {
        
         return {
           url: `${LOCATION_URL}/interests`,
           method: "POST",
           body: data, // Use the data without the id in the body
         };
      }
    }),
     getHotelsByLocation: builder.mutation({
      query: (data) => {
         return {
           url: `${HOTEL_URL}/${data}`,
           method: "GET"
         };
      }
    })
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useVendorRequestMutation,
  useGetUserMutation,
  useUpdateProfileMutation,
  useAddHotelMutation,
  useGetOwnerHotelsMutation,
  useGetTop5HotelsMutation,
  useDeleteHotelMutation,
  useGetHotelMutation,
  useUpdatedHotelMutation,
  useUpdatedHotelBannerMutation,
  useCreateRoomMutation,
  useGetHotelRoomsMutation,
  useDeleteRoomMutation,
  useGetRoomMutation,
  useUpdateRoomMutation,
  useUpdateRoomImagesMutation,
  useGetAllHotelsMutation,
  useGetVendorRequestsMutation,
  useApproveVendorRequestMutation,
  useUpdateHotelGalleryImagesMutation,
  useGetLocationsMutation,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useGetLocationMutation,
  useUpdateLocationMutation,
  useGetInterestMutation,
  useGetHotelsByLocationMutation,
  useGetAHotelRoomsMutation
} = userApiSlice;
