import React from 'react'
import store from './store';
import { Provider } from 'react-redux';
import ReactDom from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
import App from './App'


const root = ReactDom.createRoot(document.getElementById('root'))
root.render(
<Provider store={store}>
    <React.StrictMode>
    <BrowserRouter>
    <App/>
    </BrowserRouter>
    </React.StrictMode>
</Provider>
);