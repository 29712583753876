import React from 'react'
import { Routes, Route} from 'react-router-dom'
import {
  Home,
  Login,
  Register,
  Error,
  User,
  CreateHotel,
  MyHotels,
  EditHotel,
  AllHotel,
  Rooms,
  NewRoom,
  EditRoom,
  Location,
  HotelLocation,
} from "./pages";
import { Hotel } from './pages/Hotel';
import { SingleHotelPage } from './pages/SingleHotelPage';
import { AllHotels, Admin, Upgrade, CreateHotelAdmin, Locations } from './admin';
// core styles are required for all packages
import '@mantine/core/styles.css'
import { MantineProvider } from '@mantine/core';
import PrivateRoute from './components/PrivateRoute'

const App = () => {
  return (
    <MantineProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/allhotels" element={<Hotel />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* Private route */}
        <Route path="" element={<PrivateRoute />}>
          <Route path="/user" element={<User />} />
          <Route path="/hotel/create" element={<CreateHotel />}></Route>
          <Route path="/hotel/hotels" element={<MyHotels />} />
          <Route path="/hotel/edit/:id" element={<EditHotel />} />
          <Route path="/hotel/room/:id" element={<Rooms />} />
          <Route path="/hotel/room/create/:id" element={<NewRoom />} />
          <Route path="/hotel/room/edit/:id" element={<EditRoom />} />
          <Route path="/hotels" element={<AllHotel />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/all-hotels" element={<AllHotels />} />
          <Route path="/admin/upgrade_request" element={<Upgrade />} />
          <Route path="/admin/hotel/create" element={<CreateHotelAdmin />} />
          <Route path="/admin/locations" element={<Locations />} />
        </Route>
        <Route path="*" element={<Error />} />
        <Route path="/hotel/id/:id" element={<SingleHotelPage />} />
        <Route path="/location/:interest?" element={<Location />} />
        <Route path="/hotel/:location" element={<HotelLocation />} />
      </Routes>
    </MantineProvider>
  );
}

export default App