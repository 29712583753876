import { useState } from "react";
import {
  Stepper,
  Button,
  Group,
  TextInput,
  Code,
  Textarea,
  Radio,
  Rating,
  CheckIcon,
  Checkbox,
  Flex,
  Select,
  rem,
  Grid,
  NumberInput,
  Text,
  Image,
  SimpleGrid,
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { TimeInput } from "@mantine/dates";
import {
  IconMapPin,
  IconCurrencyNaira,
  IconPhoto,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import classes from "./createhotel.module.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAddHotelMutation } from "../slices/usersApiSlice";
import { useNavigate } from "react-router-dom";

export default function CreateHotelContent() {
  const [hotel, { isLoading }] = useAddHotelMutation();
  const [active, setActive] = useState(0);
  const [banner, setBanner] = useState([]);
  const navigate = useNavigate();
  const locations = [
    "Lagos, Nigeria",
    "Enugu, Nigeria",
    "Ibadan, Nigeria",
    "Abuja, Nigeria",
    "Osun, Nigeria",
    "Calabar, Nigeria",
    "Benin, Nigeria",
    "Ekiti, Nigeria",
    "Ilorin, Nigeria",
    "Ikeja, Lagos",
  ];

  //previews the banner image
  const previews = banner.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        src={imageUrl}
        onLoad={() => URL.revokeObjectURL(imageUrl)}
        className={classes.preview}
      />
    );
  });

  const form = useForm({
    initialValues: {
      title: "",
      description: "",
      rating: "N/A",
      features: [],
      location: "",
      address: "",
      check_in: "",
      check_out: "",
      price: "",
      banner: banner,
    },

    validate: (values) => {
      if (active === 0) {
        return {
          title:
            values.title.trim().length < 6
              ? "Hotel name must include at least 6 characters"
              : null,
          description:
            values.description.length < 2
              ? "Description must include at least 2 characters"
              : null,
        };
      }

      //validation on third step
      if (active === 2) {
        return {
          location:
            values.location.trim().length < 2 ? "Please select location" : null,
          address:
            values.address.trim().length < 2
              ? "Address must include at least 2 characters"
              : null,
        };
      }
      //validation on fourth step
      if (active === 3) {
        return {
          check_in:
            values.check_in.trim() === ""
              ? "Please select check-in time"
              : null,
          check_out:
            values.check_out.trim() === ""
              ? "Please select checkout time"
              : null,
          price: values.price < 5 ? "Price should be greater than 5" : null,
        };
      }

      return {};
    },
  });

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 5 ? current + 1 : current;
    });

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const handleBannerDrop = (acceptedFiles) => {
    setBanner(acceptedFiles);
    form.setValues((prevValues) => ({
      ...prevValues,
      banner: acceptedFiles[0],
    }));
  };

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("rating", values.rating);

      // Append features individually
      values.features.forEach((feature, index) => {
        formData.append("features", feature);
      });

      formData.append("location", values.location);
      formData.append("address", values.address);
      formData.append("check_in", values.check_in);
      formData.append("check_out", values.check_out);
      formData.append("price", values.price);
      formData.append("banner", values.banner);

      const response = await hotel(formData);
      // Check for specific success conditions
      if (response.data?.status !== 201) {
        toast.error(response.error.data.message || response.error);
      } else {
        // Handle unexpected success response status
        // toast.success('Hotel submitted successfully')
        navigate("/hotel/hotels");
      }
    } catch (error) {
      // Exception: Display error toast
      toast.error(error?.data?.message || error.error);
    }
  };

  return (
    <section className={classes.section}>
      <ToastContainer />
      <h2 className={classes.heading}>Add a new Hotel</h2>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <div className={classes.grid}>
          <Stepper active={active}>
            {/* first step starts*/}
            <Stepper.Step label="First step" description="General Info">
              <TextInput
                label="What is the name of your hotel?"
                description="This is the name guests will see when they search for a place to stay."
                {...form.getInputProps("title")}
                mt={20}
                required
              />
              <Textarea
                label="Description"
                description="Brief content about your hotel"
                mt={20}
                {...form.getInputProps("description")}
                required
              />
              <Radio.Group
                label="What is the star rating of your hotel?"
                {...form.getInputProps("rating")}
                withAsterisk
                mt={20}
              >
                <Radio icon={CheckIcon} mt={5} value="N/A" label="N/A" />
                <Radio
                  icon={CheckIcon}
                  mt={5}
                  value="1"
                  label={<Rating defaultValue={1} readOnly />}
                />
                <Radio
                  icon={CheckIcon}
                  mt={5}
                  value="2"
                  label={<Rating defaultValue={2} readOnly />}
                />
                <Radio
                  icon={CheckIcon}
                  mt={5}
                  value="3"
                  label={<Rating defaultValue={3} readOnly />}
                />
                <Radio
                  icon={CheckIcon}
                  mt={5}
                  value="4"
                  label={<Rating defaultValue={4} readOnly />}
                />
                <Radio
                  icon={CheckIcon}
                  mt={5}
                  value="5"
                  label={<Rating defaultValue={5} readOnly />}
                />
              </Radio.Group>
            </Stepper.Step>
            {/* first step ends*/}

            {/* second step starts*/}
            <Stepper.Step
              label="Second step"
              description="Features of your hotel"
            >
              <Checkbox.Group {...form.getInputProps("features")} mt={20}>
                <Flex direction="row" gap="md" wrap="wrap">
                  <Checkbox value="Restaurant" label="Restaurant" />
                  <Checkbox value="Parking space" label="Parking space" />
                  <Checkbox value="Bar" label="Bar" />
                  <Checkbox value="Sauna" label="Sauna" />
                  <Checkbox value="Wake-up call" label="Wake-up call" />
                  <Checkbox value="Laundry services" label="Laundry services" />
                  <Checkbox value="Car hire" label="Car hire" />
                  <Checkbox value="Internet / WI-FI" label="Internet / WI-FI" />
                  <Checkbox
                    value="Transport services"
                    label="Transport services"
                  />
                  <Checkbox value="Coffee and Tea" label="Coffee and Tea" />
                  <Checkbox value="Bicycle Hire" label="Bicycle Hire" />
                  <Checkbox
                    value="Free Luggage deposite"
                    label="Free Luggage deposite"
                  />
                  <Checkbox value="Flat TV" label="Flat TV" />
                  <Checkbox value="Garden" label="Garden" />
                  <Checkbox value="Boats" label="Boats" />
                  <Checkbox value="Cruises" label="Cruises" />
                </Flex>
              </Checkbox.Group>
            </Stepper.Step>
            {/* second step ends*/}

            {/* third step starts*/}
            <Stepper.Step
              label="Third step"
              description="Location of your hotel"
            >
              <Select
                label="Location"
                description="Choose the location of your hotel"
                data={locations}
                withScrollArea={false}
                styles={{
                  dropdown: { maxHeight: 200, overflowY: "auto", zIndex: 900 },
                }}
                mt={20}
                searchable
                clearable
                nothingFoundMessage="Location not available"
                allowDeselect={false}
                withAsterisk
                leftSection={
                  <IconMapPin
                    style={{ width: rem(18), height: rem(18) }}
                    stroke={1.5}
                  />
                }
                {...form.getInputProps("location")}
              />
              <TextInput
                mt="md"
                label="Address"
                description="Precise address of your hotel"
                {...form.getInputProps("address")}
              />
            </Stepper.Step>
            {/* third step ends*/}

            {/* fourth step starts*/}
            <Stepper.Step label="Fourth step" description="Pricing">
              <Grid mt={20}>
                <Grid.Col span={6}>
                  <TimeInput
                    label="Check-in"
                    description="e.g 07:30"
                    {...form.getInputProps("check_in")}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TimeInput
                    label="Check-out"
                    description="e.g 18:30"
                    {...form.getInputProps("check_out")}
                  />
                </Grid.Col>
              </Grid>
              <NumberInput
                mt="md"
                label="Price"
                description="Set price per night"
                clampBehavior="strict"
                prefix="₦"
                min={1}
                allowNegative={false}
                allowDecimal={false}
                thousandSeparator=","
                leftSection={
                  <IconCurrencyNaira
                    style={{ width: rem(18), height: rem(18) }}
                    stroke={1.5}
                  />
                }
                {...form.getInputProps("price")}
              />
            </Stepper.Step>
            {/* fourth step ends*/}

            {/* final step starts*/}
            <Stepper.Step label="Final step" description="Banner Image">
              <Dropzone
                onDrop={handleBannerDrop}
                onReject={() => toast.error("Maximum file exceeded.")}
                maxSize={3 * 1024 ** 2}
                accept={IMAGE_MIME_TYPE}
                maxFiles={1}
                className={classes.dropzone}
                {...form.getInputProps("banner")}
              >
                <Group
                  justify="center"
                  gap="xl"
                  mih={220}
                  style={{ pointerEvents: "none" }}
                >
                  <Dropzone.Accept>
                    <IconUpload
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-blue-6)",
                      }}
                      stroke={1.5}
                    />
                  </Dropzone.Accept>

                  <Dropzone.Reject>
                    <IconX
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-red-6)",
                      }}
                      stroke={1.5}
                    />
                  </Dropzone.Reject>

                  <Dropzone.Idle>
                    <IconPhoto
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-dimmed)",
                      }}
                      stroke={1.5}
                    />
                  </Dropzone.Idle>

                  <div>
                    <Text size="xl" inline>
                      Banner Images
                    </Text>
                    <Text size="sm" c="dimmed" inline mt={7}>
                      Drag and Drop or Click to upload. (1 image only)
                    </Text>
                  </div>
                </Group>
              </Dropzone>

              <SimpleGrid
                cols={{ base: 1, sm: 4 }}
                mt={previews.length > 0 ? "xl" : 0}
              >
                {previews}
              </SimpleGrid>
            </Stepper.Step>
            {/* final step ends*/}

            <Stepper.Completed>
              Completed! Form values:
              <Code block mt="xl">
                {JSON.stringify(form.values, null, 2)}
              </Code>
            </Stepper.Completed>
          </Stepper>
        </div>

        <Group justify="flex-end" mb={100}>
          {active !== 0 && (
            <Button variant="default" onClick={prevStep}>
              Back
            </Button>
          )}
          {active !== 5 && <Button onClick={nextStep}>Next step</Button>}
          {active === 5 && (
            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Loading..." : "Publish Hotel"}
            </Button>
          )}
        </Group>
      </form>
    </section>
  );
}
