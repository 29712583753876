import { useState, useEffect } from 'react';
import { TextInput, Table, Checkbox, ScrollArea, Group, Button, Text, rem, Flex, Loader } from '@mantine/core';
import {  IconSearch } from '@tabler/icons-react';
import './Upgradetwo.css';
import { useGetVendorRequestsMutation, useApproveVendorRequestMutation } from '../slices/usersApiSlice';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

 function Upgrade() {
  const [requests, {isLoading}] = useGetVendorRequestsMutation()
   const [approve_request, { isLoading: isApproveLoading }] = useApproveVendorRequestMutation()
  const [data, setData] = useState([])

   const getRequests = async () => {
    try {
      const data = await requests().unwrap()
      const rows = data.users.map((item) => {
        const { name, email, phone, vendorStatus, _id } = item;
        return (
          <Table.Tr key={_id}>
            <Table.Td>
              <Checkbox />
            </Table.Td>
            <Table.Td>
              <Group gap="sm">
                {/* <Avatar size={26} src={item.avatar} radius={26} /> */}
                <Text size="sm" fw={500} tt="capitalize">
                  {name}
                </Text>
              </Group>
            </Table.Td>
            <Table.Td>{email}</Table.Td>
            <Table.Td>{phone}</Table.Td>
            <Table.Td>Vendor</Table.Td>
            <Table.Td>
              <Button
                variant="filled"
                color={vendorStatus === "pending" ? "orange" : "green"}
                onClick={(event) => event.preventDefault()}
              >
                <Text tt="capitalize">{vendorStatus}</Text>
              </Button>
            </Table.Td>
            <Table.Td>
              {vendorStatus === "pending" ? (
                <Button
                  variant="filled"
                  onClick={() => approveVendor(_id)}
                  loading={isApproveLoading}
                  loaderProps={{ type: "dots" }}
                >
                  <Text tt="capitalize">approve</Text>
                </Button>
              ) : (
                ""
              )}
            </Table.Td>
          </Table.Tr>
        );
      });
    setData(rows)
    return
    } catch (error) {
      console.log(error)
      toast.error(error?.data?.message || error.error);
    }
  }

  useEffect(() => {
      getRequests()
      // eslint-disable-next-line
  }, []);
   
   const approveVendor = async(id) => {
     await approve_request(id)
    getRequests()
  }



  return (
    <div className="abdul">
      <ToastContainer />
      <ScrollArea>
        <h2 className="tin">Vendor Request</h2>
        <TextInput
          className="tin"
          placeholder="Search by any field"
          mb="md"
          leftSection={
            <IconSearch
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          }
        />

        {isLoading ? (
          <Flex justify="center" w="20vw" h="20vh" align="center">
            <Loader color="blue" size={50} />
          </Flex>
        ) : (
          <Table miw={800} verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ width: rem(40) }}>
                  <Checkbox
                  // onChange={toggleAll}
                  // checked={selection.length === data.length}
                  // indeterminate={selection.length > 0 && selection.length !== data.length}
                  />
                </Table.Th>
                <Table.Th>Name</Table.Th>
                <Table.Th>Email</Table.Th>
                <Table.Th>Phone</Table.Th>
                <Table.Th>Role Request</Table.Th>
                <Table.Th>Status</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{data}</Table.Tbody>
          </Table>
        )}
      </ScrollArea>
    </div>
  );
}

export default Upgrade;