import React from 'react'
import Navbar from '../features/header/Navbar'
import { Searchbar } from "../components/Searchbar";
import { LocationContent } from "../components/HotelsContent";
import {ContactUs} from '../components/ContactUs';
import { FooterLinks } from '../components/FooterLinks'

export const Hotel = () => {
  return (
    <>
      <Navbar />
      <Searchbar />
      <LocationContent />
      <ContactUs />
      <FooterLinks />
    </>
  );
}
