import { useState, useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Stepper, Button, Group,Modal, TextInput, Code, Checkbox, Flex, rem, Grid, NumberInput, Text, Image, SimpleGrid } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconBed, IconCurrencyNaira, IconPhoto, IconUpload, IconArrowBack } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import classes from './createhotel.module.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetRoomMutation,useUpdateRoomMutation, useUpdateRoomImagesMutation } from '../slices/usersApiSlice';
import { useNavigate, useParams } from 'react-router-dom';

export function EditRoomContent() {
  const [single_room] = useGetRoomMutation()
  const [room, { isLoading }] = useUpdateRoomMutation();
  const [room_images_update, {isLoading: isGallaryLoading}] = useUpdateRoomImagesMutation()
  const [active, setActive] = useState(0);
  const [title, setTitle] = useState('')
  const [hotelId, setHotelId] = useState('')
  const navigate = useNavigate()
  const {id} = useParams()
  const [opened, { open, close }] = useDisclosure(false);
  const [currentGallery, setCurrentGallery] = useState([])
  const [newGallary, setNewGallary] = useState([])


  //previews the banner image
  const previews = newGallary.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        h={90}
        w={90}
        src={imageUrl}
        onLoad={() => URL.revokeObjectURL(imageUrl)}
        className={classes.preview}
      />
    );
  });


  const form = useForm({
    initialValues: {
      title: '',
      features: [],
      price: '',
      no_of_rooms: '',
      no_of_beds: '',
      room_size: ''
    },

    validate: (values) => {
        if (active === 0) {
            return {
              title: values.title.trim().length < 3
                ? 'Room name must include at least 3 characters'
                : null
            };
          }
    
          //validation on third step
          if (active === 1) {
            return {
              price: values.price< 1
                ? 'Please include price'
                : null,
              no_of_beds: values.no_of_beds < 1
                ? 'Please include number of beds'
                : null,
              no_of_rooms: values.no_of_rooms < 1
                ? 'Please include number of rooms'
                : null,
              room_size: values.room_size < 1
                ? 'Please include room size'
                : null,
            };
          }

      return {};
    },
  });


  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 5 ? current + 1 : current;
    });

  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

const gallaryForm = useForm({
  initialValues: {
    gallary: newGallary
  }})

  const handleUpdateRoomImages = async(values)=>{
    try {
      const formData = new FormData()
         // Append features individually
         values.gallary.forEach((gallary, index) => {
            formData.append('gallary', gallary);
          });
      formData.append('id', id)
      const response = await room_images_update(formData);

        // Check for specific success conditions
    if (response.data?.status !== 200) {
      toast.error(response.error.data.message || response.error);
    } else {
      // Handle unexpected success response status
      toast.success('Gallary Images updated successfully')
      setCurrentGallery(response.data.data.gallary)
      setNewGallary([])
    }
    } catch (error) {
      toast.error(error?.data?.message || error.error)
    }
  }

  const getHotel = async()=>{
    try {
      const response = await single_room(id).unwrap()
      const data = {
        title:response.data.title,
        price:response.data.price,
        no_of_beds: response.data.no_of_beds,
        no_of_rooms: response.data.no_of_rooms,
        room_size: response.data.room_size,
        features: response.data.features
        }
      setTitle(response.data.title)
      setCurrentGallery(response.data.gallary)
      setHotelId(response.data.associatedWith)
      form.setValues(data);
    } catch (error) {
        toast.error(error?.data?.message || error.error)
    }
  }
  useEffect(()=>{
    getHotel()
    // eslint-disable-next-line
   }, [])

   const handleUpdateRoom = async (values) => {
    try {
      const valuesWithId = {...values, id}
      const response = await room(valuesWithId).unwrap()
    navigate(`/hotel/room/${hotelId}`)
      return response
    } catch (error) {
      // Exception: Display error toast
      toast.error(error?.data?.message || error.error);
    }
  };

  const handleBannerDrop = (acceptedFiles) => {
    setNewGallary(acceptedFiles);
    gallaryForm.setValues((prevValues) => ({ ...prevValues, gallary: acceptedFiles }));
  };

  return (
    <section className={classes.section}>
      <ToastContainer />
      <Modal
        opened={opened}
        onClose={close}
        title="Change Room Images"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        zIndex={500}
      >
          <form onSubmit={gallaryForm.onSubmit((values) => handleUpdateRoomImages(values))}>
      <Dropzone accept={IMAGE_MIME_TYPE} onDrop={handleBannerDrop} className={classes.secondDropzone} maxFiles={5} {...gallaryForm.getInputProps('gallary')} onReject={() => toast.error('Maximum file exceeded.')} >
        <Text size="sm" c="dimmed" ta="center">Drag and Drop or Click to upload. (maximum of 5 Images)</Text>
      </Dropzone>

      <SimpleGrid cols={{ base: 1, sm: 2 }} mt={previews.length > 0 ? 'md' : 0}>
        <div>
          <Text fz="sm">Current Room Images</Text>
          <Flex
               miw={200}
               gap="md"
               justify="flex-start"
               align="center"
               direction="row"
               wrap="wrap">
          {currentGallery.map((image, index)=>{
            return <Image h={90} w={90} src={image} key={index}/>
          })}
          </Flex>
        </div>
        <div>
        <Text fz='sm'>New Room Image</Text>
        <Flex
               miw={200}
               gap="md"
               justify="flex-start"
               align="center"
               direction="row"
               wrap="wrap">
                {previews}
          </Flex>
        </div>
      </SimpleGrid>
      <Flex
      justify='flex-end'>

      <Button type='submit' mt={10} disabled={isGallaryLoading} rightSection={<IconUpload/>}>{isGallaryLoading ? 'Loading...' : 'Update Images'}</Button>
      </Flex>
      </form>
      </Modal>
      <Flex
       justify={{ sm: 'space-between'}}
       align="center"
       px={15}
       direction={{ base: 'column', sm: 'row' }}>
      <h2 className={classes.heading}>Edit "{title}" Room</h2>
      <Group justify="flex-start">
      <Button leftSection={<IconPhoto size={14} />} variant="filled" color="rgba(143, 189, 79, 1)" onClick={open}>
        Change Gallery Images
      </Button>
      <Button leftSection={<IconArrowBack size={14} />} variant="filled" color= 'cyan' onClick={()=>navigate(`/hotel/room/${hotelId}`)}>
        Back to rooms
      </Button>
    </Group>
    </Flex>

    <form onSubmit={form.onSubmit((values) => handleUpdateRoom(values))}>
        <div className={classes.grid}>

          <Stepper active={active}>
            {/* first step starts*/}
            <Stepper.Step label="First step" description="General Info">
              <TextInput label="What is the name of this room?" description="This is the name guests will see when they search for your hotel" {...form.getInputProps('title')} mt={20} required />
              <Checkbox.Group {...form.getInputProps('features')} mt={20} label='Features' description="These are features that are offered regardig this room">
                <Flex direction="row" gap="md" wrap="wrap" mt={10}>
                  <Checkbox value="Restaurant" label="Restaurant" />
                  <Checkbox value="Parking space" label="Parking space" />
                  <Checkbox value="Bar" label="Bar" />
                  <Checkbox value="Sauna" label="Sauna" />
                  <Checkbox value="Wake-up call" label="Wake-up call" />
                  <Checkbox value="Laundry services" label="Laundry services" />
                  <Checkbox value="Car hire" label="Car hire" />
                  <Checkbox value="Internet / WI-FI" label="Internet / WI-FI" />
                  <Checkbox value="Transport services" label="Transport services" />
                  <Checkbox value="Coffee and Tea" label="Coffee and Tea" />
                  <Checkbox value="Bicycle Hire" label="Bicycle Hire" />
                  <Checkbox value="Free Luggage deposite" label="Free Luggage deposite" />
                  <Checkbox value="Flat TV" label="Flat TV" />
                  <Checkbox value="Garden" label="Garden" />
                  <Checkbox value="Boats" label="Boats" />
                  <Checkbox value="Cruises" label="Cruises" />
                </Flex>
              </Checkbox.Group>
            </Stepper.Step>
            {/* first step ends*/}

            {/* second step starts*/}
            <Stepper.Step label="Second step" description="Pricing">
            <Grid mt={20}
            >
                <Grid.Col span={6}>
                  <NumberInput label="Price" description="Set price per night" clampBehavior="strict" prefix="₦" min={1} allowNegative={false} allowDecimal={false} thousandSeparator="," leftSection={<IconCurrencyNaira style={{ width: rem(18), height: rem(18) }} stroke={1.5} />} {...form.getInputProps('price')}/>
                  <NumberInput mt="md" label="Number of beds" description="How many beds are in this room?" clampBehavior="strict" min={1} allowNegative={false} allowDecimal={false} thousandSeparator="," leftSection={<IconBed style={{ width: rem(18), height: rem(18) }} stroke={1.5} />} {...form.getInputProps('no_of_beds')}/>
                </Grid.Col>
                <Grid.Col span={6}>
                  <NumberInput label="Quantity of rooms" description="How many rooms is available?" {...form.getInputProps('no_of_rooms')}/>
                  <NumberInput mt="md" label="Room size" description="Room size in square feet"  clampBehavior="strict" min={1} allowNegative={false} allowDecimal={false} thousandSeparator=","{...form.getInputProps('room_size')}/>
                </Grid.Col>
              </Grid>
            </Stepper.Step>
            {/* second step ends*/}

            <Stepper.Completed>
              Completed! Form values:
              <Code block mt="xl">
                {JSON.stringify(form.values, null, 2)}
              </Code>
            </Stepper.Completed>
          </Stepper>
        </div>

        <Group justify="flex-end" mb={100}>
          {active !== 0 && (
            <Button variant="default" onClick={prevStep}>
              Back
            </Button>
          )}
          {active !== 2 && <Button onClick={nextStep}>Next step</Button>}
          {active === 2 && <Button type="submit" disabled={isLoading} leftSection={<IconUpload/>} loading={isLoading} loaderProps={{ type: 'dots' }}>Update Room</Button>}
        </Group>
      </form>
    </section>
  );
}
