import React from 'react'
import Navbar from '../components/VendorNavbar'
import {MyHotelsContent} from '../components/MyHotelsContent'
import classes from './user.module.css'
const User = () => {
  return (
    <>
    <section className={classes.section}>
        <Navbar activeLink={'My Hotels'}/>
        <MyHotelsContent/>
    </section>
    </>
  )
}

export default User
