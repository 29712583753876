import {
    Group,
    UnstyledButton,
    Text,
    ThemeIcon,
    Divider,
    Center,
    Box,
    Burger,
    Drawer,
    Collapse,
    ScrollArea,
    rem,
    Menu,
    useMantineTheme,
    Flex
  } from '@mantine/core';
  import { useDisclosure } from '@mantine/hooks';
  import {
    IconNotification,
    IconCode,
    IconBook,
    IconChartPie3,
    IconFingerprint,
    IconCoin,
    IconChevronDown,
  } from '@tabler/icons-react';
  import classes from './Navbar.module.css';
  import { Link, useNavigate } from "react-router-dom";
  import { useSelector, useDispatch } from 'react-redux';
  import { clearCredentials } from '../../slices/authSlice';

  const mockdata = [
    {
      icon: IconCode,
      title: 'Open source',
      description: 'This Pokémon’s cry is very loud and distracting',
    },
    {
      icon: IconCoin,
      title: 'Free for everyone',
      description: 'The fluid of Smeargle’s tail secretions changes',
    },
    {
      icon: IconBook,
      title: 'Documentation',
      description: 'Yanma is capable of seeing 360 degrees without',
    },
    {
      icon: IconFingerprint,
      title: 'Security',
      description: 'The shell’s rounded shape and the grooves on its.',
    },
    {
      icon: IconChartPie3,
      title: 'Analytics',
      description: 'This Pokémon uses its flying ability to quickly chase',
    },
    {
      icon: IconNotification,
      title: 'Notifications',
      description: 'Combusken battles with the intensely hot flames it spews',
    },
  ];
  
 const Navbar = () =>{
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
    const [userLinkOpened, { toggle: toggleUserLinks }] = useDisclosure(false);
    const theme = useMantineTheme();
  const {userInfo} = useSelector((state)=>state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  //handle Logout

  const handleLogout = async(e)=>{
    e.preventDefault()
    try {
      dispatch(clearCredentials())
      navigate('/')
    } catch (error) {
      console.error(error?.data?.message || error.error) 
    }
  }

  const isUserLogin = () => {
    if (!userInfo) {
      const link = (
        <div>
          <Link to="/login" className={classes.btnDefault}>
            Log in
          </Link>
          { "  "}
          <Link to="/register" className={classes.btn}>
            Sign up
          </Link>
        </div>
      );
      return link;
    } else {
      const link = (
        <>
        <UnstyledButton className={classes.link} onClick={toggleUserLinks} >
        <Center inline>
          <Box component="span" mr={5}>
          <h3>Hi, {userInfo.name}</h3>
          </Box>
          <IconChevronDown
            style={{ width: rem(16), height: rem(16) }}
            color={theme.colors.blue[6]}
          />
        </Center>
      </UnstyledButton>
      <Collapse in={userLinkOpened}>
      <UnstyledButton className={classes.subLink} key={1}>
        <Group wrap="nowrap" align="flex-start"  >
          <Flex direction="column" gap="md">
          <Link to='/user' className={classes.tabLink}>My Profile</Link>
         <Link className={classes.tabLink} onClick={handleLogout}>Logout</Link>
          </Flex>
       
        </Group>
      </UnstyledButton>
      </Collapse>
        </>
      )
      return link;
    }
  };

  const isUserLoginWeb = () => {
    if (!userInfo) {
      const link = (
        <div>
          <Link to="/login" className={classes.btnDefault}>
            Log in
            </Link>
          { "  "}
          <Link to="/register" className={classes.btn}>
            Sign up
          </Link>
        </div>
      );
      return link;
    } else {
      const link = (
        <Menu m={20}>
          <Menu.Target>
              <UnstyledButton
                className={classes.user}
              >
                <Group gap={7}>
                  <Text fw={500} size="sm" lh={1} mr={3}>
                    Hi, {userInfo.name}
                  </Text>
                  <IconChevronDown style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                </Group>
              </UnstyledButton>
            </Menu.Target>
          <Menu.Dropdown>
          <Menu.Label>
              <Menu.Item><Link to='/user' className={classes.tabLink}>My profile</Link></Menu.Item>
              {userInfo.role === 'admin' ? <Menu.Item><Link to='/admin' className={classes.tabLink}>Admin Dashboard</Link></Menu.Item>: " "}
                {userInfo.role === 'admin' || userInfo.role === 'vendor' ? <Menu.Item><Link to='/' className={classes.tabLink}>Vendor Dashboard</Link></Menu.Item>: " "}
                <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
              </Menu.Label>
          </Menu.Dropdown>
        </Menu>
           )
      return link;
    }
  };
    const links = mockdata.map((item) => (
      <UnstyledButton className={classes.subLink} key={item.title}>
        <Group wrap="nowrap" align="flex-start">
          <ThemeIcon size={34} variant="default" radius="md">
            <item.icon style={{ width: rem(22), height: rem(22) }} color={theme.colors.blue[6]} />
          </ThemeIcon>
          <div>
            <Text size="sm" fw={500}>
              {item.title}
            </Text>
            <Text size="xs" c="dimmed">
              {item.description}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    ));
  
    return (
      <Box pb={70} mt={10}>
        <header className={classes.header}>
          <Group justify="space-between" h="100%">
            <h3>Booktripp</h3>
  
            <Group h="100%" gap={0} visibleFrom="sm">
              <Link to="/" className={classes.link}>
                Home
              </Link>
              {/* <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                <HoverCard.Target>
                  <a href="/" className={classes.link}>
                    <Center inline>
                      <Box component="span" mr={5}>
                        Features
                      </Box>
                      <IconChevronDown
                        style={{ width: rem(16), height: rem(16) }}
                        color={theme.colors.blue[6]}
                      />
                    </Center>
                  </a>
                </HoverCard.Target>
  
                <HoverCard.Dropdown style={{ overflow: 'hidden' }}>
                  <Group justify="space-between" px="md">
                    <Text fw={500}>Features</Text>
                    <Anchor href="/" fz="xs">
                      View all
                    </Anchor>
                  </Group>
  
                  <Divider my="sm" />
  
                  <SimpleGrid cols={2} spacing={0}>
                    {links}
                  </SimpleGrid>
  
                  <div className={classes.dropdownFooter}>
                    <Group justify="space-between">
                      <div>
                        <Text fw={500} fz="sm">
                          Get started
                        </Text>
                        <Text size="xs" c="dimmed">
                          Their food sources have decreased, and their numbers
                        </Text>
                      </div>
                      <Button variant="default">Get started</Button>
                    </Group>
                  </div>
                </HoverCard.Dropdown>
              </HoverCard> */}
              {/* <Link href="/allhotels" className={classes.link}>
                Hotel
              </Link> */}
              <a href='/allhotels' className={classes.link}>
                Hotel
              </a>
              <Link href="/" className={classes.link}>
                About
              </Link>
              <Link href="/" className={classes.link}>
                Contact
              </Link>
            </Group>
            <Group visibleFrom="sm">
              {isUserLoginWeb()}
            </Group>
  
            <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
          </Group>
        </header>
  
        <Drawer
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="md"
          title="Navigation"
          hiddenFrom="sm"
          zIndex={1000000}
        >
          <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
            <Divider my="sm" />
  
            <Link href="/" className={classes.link}>
            <h3>Home</h3>
            </Link>
            <UnstyledButton className={classes.link} onClick={toggleLinks}>
              <Center inline>
                <Box component="span" mr={5}>
                <h3>Features</h3>
                </Box>
                <IconChevronDown
                  style={{ width: rem(16), height: rem(16) }}
                  color={theme.colors.blue[6]}
                />
              </Center>
            </UnstyledButton>
            <Collapse in={linksOpened}>{links}</Collapse>
            <Link href="/" className={classes.link}>
              <h3>Learn</h3>
            </Link>
            <Link href="/" className={classes.link}>
            <h3>Academy</h3>
            </Link>
    
            <Group justify="center" grow pb="xl" px="md">
            <h3>{isUserLogin()}</h3>
            </Group>
          </ScrollArea>
        </Drawer>
      </Box>
    );
  }

  export default Navbar;