import React, { useState } from 'react';
import Picker from './Picker';
import { IconMap2, IconCalendar, IconUsersGroup } from '@tabler/icons-react';
import Select from 'react-select';
import './Searchbar.css';
import { Button, MultiSelect } from '@mantine/core';
import { useNavigate } from "react-router-dom";

const optionList = [
  { value: "lagos", label: "Lagos" },
  { value: "ibadan", label: "Ibadan" },
  { value: "enugu", label: "Enugu" },
];

export const Searchbar = () => {
  const [showAlternativeDropdown, setShowAlternativeDropdown] = useState(false);
  const [interest, setInterest] = useState([])
  const [isLocation, setIsLocation] = useState(true)
  const [location, setLocation] = useState('')
   const navigate = useNavigate();


  const handleButtonClick = () => {
    setShowAlternativeDropdown(true);
    setIsLocation(false);
  };

  const handleResetClick = () => {
    setShowAlternativeDropdown(false);
    setIsLocation(true);
  };

 const handleSearch = (event) => {
   event.preventDefault();
   if (isLocation) {
     console.log(location)
     navigate(`/hotel/${location.value}`)
   } else {
     // Convert the interest array to a string of comma-separated values
     const interestString = interest.join(",");

     // Navigate to the "/location" route with query parameters
     navigate(`/location/${encodeURIComponent(interestString)}`);
   }
 };

  return (
    <>
      <section className="hero">
        <div>
          <h1 className="pl">Hi There!</h1>
          <p>Where would you like to go?</p>
          <form onSubmit={handleSearch}>
            <section className="where">
              <Button className="fir" onClick={handleButtonClick}>
                What experience 're you looking to have?
              </Button>
              <Button className="sec" onClick={handleResetClick}>
                I know my destination
              </Button>
            </section>
            <div className="grid-container">
              <div
                className={`select ${
                  showAlternativeDropdown ? "alternative-dropdown" : ""
                }`}
              >
                <p>
                  <IconMap2 />
                  <span>Location</span>
                </p>
                {showAlternativeDropdown ? (
                  /* Render your alternative multiselect dropdown here */

                  <MultiSelect
                    // {...form.getInputProps("features")}
                    placeholder="Select places of interest"
                    data={[
                      "Mountain",
                      "Safari",
                      "Beach",
                      "Snow",
                      "Water parks",
                      "City center",
                      "Lakes",
                      "Rivers",
                      "Palm trees",
                      "Deserts",
                      "Conservatories",
                      "Monuments",
                      "Castles",
                      "Heritage sites",
                      "Blue Sky",
                      "Waterfall",
                      "Chinese foods",
                      "Caves",
                      "Museum",
                      "Art Gallary",
                      "Tall buildings",
                      "Zoo",
                      "Forest",
                    ]}
                    // styles={{
                    //   dropdown: { zIndex: 900 },
                    // }}
                    searchable
                    clearable
                    nothingFoundMessage="Feature not available..."
                    hidePickedOptions
                    maxDropdownHeight={200}
                    withAsterisk
                    value={interest}
                    onChange={setInterest}
                  />
                ) : (
                  /* Render the default dropdown when the button is not clicked */
                  <Select
                    options={optionList}
                    isSearchable
                    placeholder="Where are you going?"
                      className="select"
                      onChange={setLocation}
                  />
                )}
              </div>
              <div className="select">
                <p>
                  <IconCalendar />
                  <span>Check In - Out</span>
                </p>
                <Picker />
              </div>
              <div className="select">
                <p>
                  <IconUsersGroup />
                  <span>Guests</span>
                </p>
                <Select
                  options={optionList}
                  isSearchable
                  placeholder="Select Guests"
                  className="select"
                />
              </div>
              <div className="num4">
                <button className="tnn" type="submit">
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};
