import React from 'react'
import Navbar from '../admin-components/AdminNavbar'
import AdminContent from '../admin-components/AdminContent'
import classes from './admin.module.css'
const User = () => {
  return (
    <>
    <section className={classes.section}>
        <Navbar activeLink={'Dashboard'}/>
        <AdminContent/>
    </section>
    </>
  )
}

export default User
