import React, {useEffect, useState} from 'react'
import Navbar from '../features/header/Navbar';
import { Container, SimpleGrid, rem, Image, Button } from '@mantine/core';
import  './SingleHotelPage.css';
import Toggle from '../components/Toggle';
// import Caro from '../components/Caro';
import { IconBedFilled } from '@tabler/icons-react';
import { IconUsers } from '@tabler/icons-react';
import { IconMan } from '@tabler/icons-react';
import {useGetHotelMutation, useGetAHotelRoomsMutation} from '../slices/usersApiSlice' 
import { useParams } from 'react-router-dom';
import Select from "react-select";


const PRIMARY_COL_HEIGHT = rem(300);

export const SingleHotelPage = () => {
  const [hotel] = useGetHotelMutation();
  const [rooms] = useGetAHotelRoomsMutation()
  const { id } = useParams()
  const [title, setTitle] = useState('')
  const [address, setAddress] = useState('')
  const [banner, setBanner] = useState('')
  const [gallary, setGallary] = useState([])
  const [description, setDescription] = useState('')
  const [room, setRooms] = useState([])
  const [options, setOptions] = useState([])


  const getHotel = async () => {
    const response = await hotel(id).unwrap();
    setTitle(response.title)
    setBanner(response.banner)
    setGallary(response.gallery);
    setAddress(response.address);
    setDescription(response.description);
  }

  const getRooms = async () => {
   try {
     const response = await rooms(id).unwrap();
     setRooms(response);
     //  const optionArr = []
     //  setOptions([]) 
     generateOptionsArray(response);
     console.log(response);
   } catch (error) {
    alert('Rooms unavailable for this hotel')
   }
  }

 const generateOptionsArray = (roomsData) => {
   const optionsArray = [];

   roomsData.forEach((room) => {
     for (let i = 1; i <= 10; i++) {
       optionsArray.push({
         value: room.price * i,
         label: `${i} Night(s) - #${room.price * i}`,
       });
     }
   });

   setOptions(optionsArray);
 };


  useEffect(() => {
    getHotel();
    getRooms();
    // eslint-disable-next-line
  }, [id])
  return (
    <section>
      <Navbar />
      <hr className="line" />
      <br />
      <br />

      {/* <div className="ero"> */}
        <img
          className="ero"
          src={banner}
          alt=""
          // style={{ height: PRIMARY_COL_HEIGHT, borderRadius: rem(8) }}
        />
      {/* </div> */}
      <br />

      <h1 className="hotelrom">{title}</h1>
      <p className="hotelrom">{address}</p>
      <Container my="md">
        <SimpleGrid cols={{ base: 1, sm: 2 }} spacing="md">
          {/* <Skeleton height={PRIMARY_COL_HEIGHT} radius="md" animate={false} /> */}
          {gallary.map((image, index) => (
            <img
              key={index}
              className="rff"
              src={image}
              alt=""
              style={{ height: PRIMARY_COL_HEIGHT, borderRadius: rem(8) }}
            />
          ))}
        </SimpleGrid>
        <br />
        <div className="main">
          <div className="secon"></div>
          <div className="secon"></div>
          <div className="secon"></div>
        </div>
      </Container>
      <br />
      <div className="descrip">
        <h2 className="ent">Description</h2>
        <p>{description}</p>
      </div>
      <br />
      <h2 className="ent">Available Rooms</h2>
      <div className="tol">
        <Toggle />
      </div>
      <br />
      <br />
      <br />

      {room.map((room, index) => {
        return (
          <div className="maindiv" key={index}>
            <div className="firstcol">
              <Image
                radius="md"
                h={200}
                w="auto"
                fit="contain"
                src={room.gallary[0]}
              />
            </div>
            <div className="seconcol">
              {room.title}
              <br />
              <br />
              <div className="con">
                <IconBedFilled />
                <IconUsers />
                <IconMan />
              </div>
              <div className="lon">
                <div>x{room.no_of_beds}</div>
                <div>x{room.no_of_rooms}</div>
                <div>x{room.room_size}</div>
              </div>
            </div>
            <div className="thirdcol">
              #{room.price}/Night
              <Select
                options={options}
                isSearchable
                placeholder="Select number of nights"
                className="select"
                onChange={(selectedOption) => setOptions(selectedOption)}
              />
              {/* Other items ... */}
            </div>
          </div>
        );
      })}
      <br />
      {/* book now div */}
      <div className="booknow">
        <div className="totalroom">
          <div>Total Room</div>
          <br />
          <div>Service Fee</div>
        </div>

        <div className="cost">
          <div>Total Price</div>
          <br />
          <div>
            <Button>Book Now</Button>
          </div>
        </div>
      </div>
      <br />
    </section>
  );
}
