import React, {useState, useEffect} from 'react'
import { IconEye, IconTrash, IconEdit } from '@tabler/icons-react';
import {
    SimpleGrid,
    Divider,
    Card,
    Text, 
    Image,
    Group,
    Button,
    Flex
  } from "@mantine/core";
import classes from './myhotel.module.css';
import {useGetOwnerHotelsMutation, useDeleteHotelMutation} from "../slices/usersApiSlice";
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom';

const MyHotelsContent = () => {
    const [total, setTotal] = useState(0)
    const [hotels] = useGetOwnerHotelsMutation()
    const [delete_Hotel] = useDeleteHotelMutation()
    const [data, setData] = useState([])
    const navigate = useNavigate()

    const getHotels = async()=>{
        try {
          const response = await hotels().unwrap()
          if(response.length === 0){
            return 'No hotels was found'
        }
        setTotal(response.total)
        const hotel = response.data.map((item)=>{
            const {banner, address, title, price, _id } = item
            return (
                <Card withBorder radius="md" className={classes.card} key={_id}>
                <Card.Section>
                    <a href='/'>
                        <Image src={banner} height={240} />
                    </a>
                </Card.Section>
    
                <a href='/hotel' className={classes.title} fw={500}>
                    {title}
                </a>
                
                <Text fz="sm" className={classes.address}>
                    {address}
                </Text>
                <Group justify="space-between" className={classes.footer}>
                    <Text inline className={classes.price}>
                        starting from <span>#{price}</span>
                    </Text>   
                </Group>
                <Flex mih={50} gap="md" justify="center" align="center" direction="row" wrap="wrap" >
                    <Button size='xs' color='green' leftSection={<IconEdit size={14} />} variant="filled" onClick={()=>navigate(`/hotel/edit/${_id}`)}>Edit</Button>
                    <Button size='xs' leftSection={<IconEye size={14} />} variant="filled">View</Button>
                    <Button size='xs' color="red" leftSection={<IconTrash size={14} />} variant="filled" onClick={()=>deleteHotel(_id)}>Delete</Button>
               
            </Flex>
    </Card>
            )
        })
        setData(hotel)
          return hotel
        } catch (error) {
            toast.error(error?.data?.message || error.error)
        }
      }
      const deleteHotel = async(id)=>{
        try {
            const response = await delete_Hotel(id).unwrap()
            getHotels()
            toast.success(response.message)
            return response
        } catch (error) {
      toast.error(error?.data?.message || error.error) 
        }
      }
    
    
      useEffect(()=>{
       getHotels()
       // eslint-disable-next-line
      }, [])

  return (
    <section className={classes.section}>
        <ToastContainer/>
       <h2 className={classes.heading}>All Hotels</h2>
       <Divider/>
       <Text>Showing result for <span>{total}</span> hotels</Text>
       
    <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }} spacing={{ base: 10, sm: 'xl' }} verticalSpacing={{ base: 'md', sm: 'xl' }}>
        {data}
    </SimpleGrid>
    </section>  )
}

export {MyHotelsContent}