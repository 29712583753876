import React from 'react'
import Navbar from '../admin-components/AdminNavbar'
import { AllHotelsContent} from '../admin-components/AllHotelsContent'
import classes from './admin.module.css'

const Hotels = () => {
  return (
    <>
      <section className={classes.section}>
        <Navbar activeLink={"All Hotels"} />
        <AllHotelsContent />
      </section>
    </>
  );
}

export default Hotels
