import React from 'react'
import Navbar from '../features/header/Navbar';
import { Searchbar } from '../components/Searchbar';
import { TopHotels } from '../components/Carousel';
import { Destinations } from '../components/Destinations';
import {ContactUs} from '../components/ContactUs';
import { FooterLinks } from '../components/FooterLinks';
// import { HeroSection } from '../components/Hero';

const Home = () => {
  return (
    <>
    <Navbar />
    <Searchbar />
    <TopHotels />
    <Destinations />
    <ContactUs />
    <FooterLinks />
    </>
  )
}

export default Home;
