import { useState, useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Stepper, Button, Group,Modal, TextInput, Code, Textarea, Radio, Rating, CheckIcon, Checkbox, Flex, Select, rem, Grid, NumberInput, Text, Image, SimpleGrid } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { TimeInput } from '@mantine/dates';
import { IconMapPin, IconCurrencyNaira, IconPhoto, IconUpload, IconDoor } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import classes from './createhotel.module.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useGetHotelMutation,
  useUpdatedHotelMutation,
  useUpdatedHotelBannerMutation,
  useUpdateHotelGalleryImagesMutation,
} from "../slices/usersApiSlice";
import { useNavigate, useParams } from 'react-router-dom';

export function EditHotelContent() {
  const [single_hotel] = useGetHotelMutation();
  const [hotel, { isLoading }] = useUpdatedHotelMutation();
  const [banner, { isLoading: isBannerLoading }] =
    useUpdatedHotelBannerMutation();
  const [update_gallery, { isLoading: isGallaryLoading }] =
    useUpdateHotelGalleryImagesMutation();
  
  const [active, setActive] = useState(0);
  const [title, setTitle] = useState("");
  const [hotelId, setHotelId] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [opened, { open, close }] = useDisclosure(false);
  const [openedgallery, { open: openG, close: closeG }] = useDisclosure(false);
  const [currentBanner, setCurrentBanner] = useState("");
  const [currentGallery, setCurrentGallery] = useState([]);
  const [newGallery, setNewGallery] = useState([]);
  const [newBanner, setNewBanner] = useState([]);
  const locations = [
    "Lagos, Nigeria",
    "Enugu, Nigeria",
    "Ibadan, Nigeria",
    "Abuja, Nigeria",
    "Osun, Nigeria",
    "Calabar, Nigeria",
    "Benin, Nigeria",
    "Ekiti, Nigeria",
    "Ilorin, Nigeria",
    "Ikeja, Lagos",
  ];

  //previews the banner image
  const previews = newBanner.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        h={200}
        src={imageUrl}
        onLoad={() => URL.revokeObjectURL(imageUrl)}
        className={classes.preview}
      />
    );
  });

  //previews the banner image
  const galleryPreviews = newGallery.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        h={90}
        w={90}
        src={imageUrl}
        onLoad={() => URL.revokeObjectURL(imageUrl)}
        className={classes.preview}
      />
    );
  });

  const form = useForm({
    initialValues: {
      title: "",
      description: "",
      rating: "N/A",
      features: [],
      location: "",
      address: "",
      check_in: "",
      check_out: "",
      price: "",
    },

    validate: (values) => {
      if (active === 0) {
        return {
          title:
            values.title.trim().length < 6
              ? "Hotel name must include at least 6 characters"
              : null,
          description:
            values.description.length < 2
              ? "Description must include at least 2 characters"
              : null,
        };
      }

      //validation on third step
      if (active === 2) {
        return {
          location:
            values.location.trim().length < 2 ? "Please select location" : null,
          address:
            values.address.trim().length < 10
              ? "Address must include at least 10 characters"
              : null,
        };
      }
      //validation on fourth step
      if (active === 3) {
        return {
          check_in:
            values.check_in.trim() === ""
              ? "Please select check-in time"
              : null,
          check_out:
            values.check_out.trim() === ""
              ? "Please select checkout time"
              : null,
          price: values.price < 5 ? "Price should be greater than 5" : null,
        };
      }

      return {};
    },
  });

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 5 ? current + 1 : current;
    });

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const bannerForm = useForm({
    initialValues: {
      banner: newBanner,
    },
  });
  const galleryForm = useForm({
    initialValues: {
      gallery: newGallery,
    },
  });

  const handleUpdateHotelBanner = async (values) => {
    try {
      const id = hotelId;
      const formData = new FormData();
      formData.append("banner", values.banner);
      formData.append("id", id);
      const response = await banner(formData);
      console.log(response)
      // Check for specific success conditions
      if (response.data?.status !== 200) {
        toast.error(response.error.data.message || response.error);
      } else {
          // Handle unexpected success response status
          toast.success("Banner uploaded successfully");
          setCurrentBanner(response.data.data.banner);
          setNewBanner([]);
      }
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const handleUpdateGalleryImage = async (values) => {
      console.log(values)
      try {
      const id = hotelId;
        const formData = new FormData();
        // Append features individually
        values.gallery.forEach((gallery, index) => {
          formData.append("gallery", gallery);
        });
        formData.append("id", id);
        const response = await update_gallery(formData);
console.log(response)
        // Check for specific success conditions
        if (response.data?.status !== 200) {
          toast.error(response.error.data.message || response.error);
        } else {
          // Handle unexpected success response status
          toast.success("Gallery uploaded successfully");
          setCurrentGallery(response.data.data.gallery);
          setNewGallery([]);
        }
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    };

  const getHotel = async () => {
    try {
      const response = await single_hotel(id).unwrap();
      const data = {
        title: response.title,
        description: response.description,
        rating: response.rating,
        location: response.location,
        address: response.address,
        check_in: response.check_in,
        check_out: response.check_out,
        price: response.price,
        features: response.features,
      };
      setTitle(response.title);
      setCurrentBanner(response.banner);
      setCurrentGallery(response.gallery);
      setHotelId(response._id);
      form.setValues(data);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };
  useEffect(() => {
    getHotel();
    // eslint-disable-next-line
  }, []);

  const handleUpdateHotel = async (values) => {
    try {
      const valuesWithId = { ...values, id: hotelId };
      const response = await hotel(valuesWithId).unwrap();
      navigate("/hotel/hotels");
      return response;
    } catch (error) {
      // Exception: Display error toast
      toast.error(error?.data?.message || error.error);
    }
  };

  const handleBannerDrop = (acceptedFiles) => {
    setNewBanner(acceptedFiles);
    bannerForm.setValues((prevValues) => ({
      ...prevValues,
      banner: acceptedFiles[0],
    }));
  };
  const handleGalleryDrop = (acceptedFiles) => {
    setNewGallery(acceptedFiles);
    galleryForm.setValues((prevValues) => ({
      ...prevValues,
      gallery: acceptedFiles,
    }));
  };

  return (
    <section className={classes.section}>
      <ToastContainer />

      {/* gallary image modal start */}

      <Modal
        opened={openedgallery}
        onClose={closeG}
        title="Change Gallary Images"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        zIndex={500}
      >
        <form
          onSubmit={galleryForm.onSubmit((values) =>
            handleUpdateGalleryImage(values)
          )}
        >
          <Dropzone
            accept={IMAGE_MIME_TYPE}
            onDrop={handleGalleryDrop}
            className={classes.secondDropzone}
            maxFiles={5}
            // {...gallaryForm.getInputProps("gallary")}
            onReject={() => toast.error("Maximum file exceeded.")}
          >
            <Text size="sm" c="dimmed" ta="center">
              Drag and Drop or Click to upload. (maximum of 5 Images)
            </Text>
          </Dropzone>

          <SimpleGrid
            cols={{ base: 1, sm: 2 }}
            mt={previews.length > 0 ? "md" : 0}
          >
            <div>
              <Text fz="sm">Current Gallery Images</Text>
              <Flex
                miw={200}
                gap="md"
                justify="flex-start"
                align="center"
                direction="row"
                wrap="wrap"
              >
                {currentGallery.map((image, index) => {
                  return <Image h={90} w={90} src={image} key={index} />;
                })}
              </Flex>
            </div>
            <div>
              <div>
                <Text fz="sm">New Gallery Images</Text>
                <Flex
                  miw={200}
                  gap="md"
                  justify="flex-start"
                  align="center"
                  direction="row"
                  wrap="wrap"
                >
                  {galleryPreviews}
                </Flex>
              </div>
            </div>
          </SimpleGrid>
          <Flex justify="flex-end">
            <Button
              type="submit"
              mt={10}
              disabled={isGallaryLoading}
              rightSection={<IconUpload />}
            >
              {isGallaryLoading ? "Loading..." : "Update Images"}
            </Button>
          </Flex>
        </form>
      </Modal>

      <Modal
        opened={opened}
        onClose={close}
        title="Change Banner Image"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        zIndex={500}
      >
        <form
          onSubmit={bannerForm.onSubmit((values) =>
            handleUpdateHotelBanner(values)
          )}
        >
          <Dropzone
            accept={IMAGE_MIME_TYPE}
            onDrop={handleBannerDrop}
            className={classes.secondDropzone}
            maxFiles={1}
            {...bannerForm.getInputProps("banner")}
            onReject={() => toast.error("Maximum file exceeded.")}
          >
            <Text size="sm" c="dimmed" ta="center">
              Drag and Drop or Click to upload. (1 image only)
            </Text>
          </Dropzone>

          <SimpleGrid
            cols={{ base: 1, sm: 2 }}
            mt={previews.length > 0 ? "md" : 0}
          >
            <div>
              <Text fz="sm">Current Banner Image</Text>
              <Image h={200} src={currentBanner} />
            </div>
            <div>
              <Text fz="sm">New Banner Image</Text>
              {previews}
            </div>
          </SimpleGrid>
          <Flex justify="flex-end">
            <Button
              type="submit"
              mt={10}
              disabled={isBannerLoading}
              rightSection={<IconUpload />}
              loading={isBannerLoading}
              loaderProps={{ type: "dots" }}
            >
              Change Image
            </Button>
          </Flex>
        </form>
      </Modal>
      <Flex
        justify={{ sm: "space-between" }}
        align="center"
        px={15}
        direction={{ base: "column", sm: "row" }}
      >
        <h2 className={classes.heading}>Edit "{title}" Hotel</h2>
        <Group justify="flex-start">
          <Button
            leftSection={<IconPhoto size={14} />}
            variant="filled"
            color="rgba(143, 189, 79, 1)"
            onClick={open}
          >
            Change Banner Image
          </Button>
          <Button
            leftSection={<IconPhoto size={14} />}
            variant="filled"
            onClick={openG}
          >
            Change Gallery Images
          </Button>
          <Button
            leftSection={<IconDoor size={14} />}
            variant="filled"
            color="yellow"
            onClick={() => navigate(`/hotel/room/${id}`)}
          >
            Manage Rooms
          </Button>
        </Group>
      </Flex>

      <form onSubmit={form.onSubmit((values) => handleUpdateHotel(values))}>
        <div className={classes.grid}>
          <Stepper active={active}>
            {/* first step starts*/}
            <Stepper.Step label="First step" description="General Info">
              <TextInput
                label="What is the name of your hotel?"
                description="This is the name guests will see when they search for a place to stay."
                {...form.getInputProps("title")}
                mt={20}
                required
              />
              <Textarea
                label="Description"
                description="Brief content about your hotel"
                mt={20}
                {...form.getInputProps("description")}
                required
              />
              <Radio.Group
                label="What is the star rating of your hotel?"
                {...form.getInputProps("rating")}
                withAsterisk
                mt={20}
              >
                <Radio icon={CheckIcon} mt={5} value="N/A" label="N/A" />
                <Radio
                  icon={CheckIcon}
                  mt={5}
                  value="1"
                  label={<Rating defaultValue={1} readOnly />}
                />
                <Radio
                  icon={CheckIcon}
                  mt={5}
                  value="2"
                  label={<Rating defaultValue={2} readOnly />}
                />
                <Radio
                  icon={CheckIcon}
                  mt={5}
                  value="3"
                  label={<Rating defaultValue={3} readOnly />}
                />
                <Radio
                  icon={CheckIcon}
                  mt={5}
                  value="4"
                  label={<Rating defaultValue={4} readOnly />}
                />
                <Radio
                  icon={CheckIcon}
                  mt={5}
                  value="5"
                  label={<Rating defaultValue={5} readOnly />}
                />
              </Radio.Group>
            </Stepper.Step>
            {/* first step ends*/}

            {/* second step starts*/}
            <Stepper.Step
              label="Second step"
              description="Features of your hotel"
            >
              <Checkbox.Group {...form.getInputProps("features")} mt={20}>
                <Flex direction="row" gap="md" wrap="wrap">
                  <Checkbox value="Restaurant" label="Restaurant" />
                  <Checkbox value="Parking space" label="Parking space" />
                  <Checkbox value="Bar" label="Bar" />
                  <Checkbox value="Sauna" label="Sauna" />
                  <Checkbox value="Wake-up call" label="Wake-up call" />
                  <Checkbox value="Laundry services" label="Laundry services" />
                  <Checkbox value="Car hire" label="Car hire" />
                  <Checkbox value="Internet / WI-FI" label="Internet / WI-FI" />
                  <Checkbox
                    value="Transport services"
                    label="Transport services"
                  />
                  <Checkbox value="Coffee and Tea" label="Coffee and Tea" />
                  <Checkbox value="Bicycle Hire" label="Bicycle Hire" />
                  <Checkbox
                    value="Free Luggage deposite"
                    label="Free Luggage deposite"
                  />
                  <Checkbox value="Flat TV" label="Flat TV" />
                  <Checkbox value="Garden" label="Garden" />
                  <Checkbox value="Boats" label="Boats" />
                  <Checkbox value="Cruises" label="Cruises" />
                </Flex>
              </Checkbox.Group>
            </Stepper.Step>
            {/* second step ends*/}

            {/* third step starts*/}
            <Stepper.Step
              label="Third step"
              description="Location of your hotel"
            >
              <Select
                label="Location"
                description="Choose the location of your hotel"
                data={locations}
                withScrollArea={false}
                styles={{
                  dropdown: { maxHeight: 200, overflowY: "auto", zIndex: 900 },
                }}
                mt={20}
                searchable
                clearable
                nothingFoundMessage="Location not available"
                allowDeselect={false}
                withAsterisk
                leftSection={
                  <IconMapPin
                    style={{ width: rem(18), height: rem(18) }}
                    stroke={1.5}
                  />
                }
                {...form.getInputProps("location")}
              />
              <TextInput
                mt="md"
                label="Address"
                description="Precise address of your hotel"
                {...form.getInputProps("address")}
              />
            </Stepper.Step>
            {/* third step ends*/}

            {/* fourth step starts*/}
            <Stepper.Step label="Final step" description="Pricing">
              <Grid mt={20}>
                <Grid.Col span={6}>
                  <TimeInput
                    label="Check-in"
                    description="e.g 07:30"
                    {...form.getInputProps("check_in")}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TimeInput
                    label="Check-out"
                    description="e.g 18:30"
                    {...form.getInputProps("check_out")}
                  />
                </Grid.Col>
              </Grid>
              <NumberInput
                mt="md"
                label="Price"
                description="Set price per night"
                clampBehavior="strict"
                prefix="₦"
                min={1}
                allowNegative={false}
                allowDecimal={false}
                thousandSeparator=","
                leftSection={
                  <IconCurrencyNaira
                    style={{ width: rem(18), height: rem(18) }}
                    stroke={1.5}
                  />
                }
                {...form.getInputProps("price")}
              />
            </Stepper.Step>
            {/* fourth step ends*/}

            <Stepper.Completed>
              Completed! Form values:
              <Code block mt="xl">
                {JSON.stringify(form.values, null, 2)}
              </Code>
            </Stepper.Completed>
          </Stepper>
        </div>

        <Group justify="flex-end" mb={100}>
          {active !== 0 && (
            <Button variant="default" onClick={prevStep}>
              Back
            </Button>
          )}
          {active !== 4 && <Button onClick={nextStep}>Next step</Button>}
          {active === 4 && (
            <Button
              type="submit"
              disabled={isLoading}
              leftSection={<IconUpload />}
              loading={isLoading}
              loaderProps={{ type: "dots" }}
            >
              Update Hotel
            </Button>
          )}
        </Group>
      </form>
    </section>
  );
}
