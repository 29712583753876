import React from "react";
import Navbar from "../admin-components/AdminNavbar";
import CreateHotelContent from "../admin-components/CreateHotelContent";
import classes from "./admin.module.css";

const CreateHotel = () => {
  return (
    <>
      <section className={classes.section}>
        <Navbar activeLink={"Add Hotel"} />
        <CreateHotelContent />
      </section>
    </>
  );
};

export default CreateHotel;
