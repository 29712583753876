import React from 'react'
import Navbar from '../components/VendorNavbar'
import {RoomsContent} from '../components/RoomsContent'
import classes from './user.module.css'
const User = () => {
  return (
    <>
    <section className={classes.section}>
        <Navbar activeLink={''}/>
        <RoomsContent/>  
    </section>
    </>
  )
}

export default User
