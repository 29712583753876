import React from 'react'
import Navbar from '../components/VendorNavbar'
import {CreateHotelContent} from '../components/CreateHotelContent'
import classes from './user.module.css'
const User = () => {
  return (
    <>
    <section className={classes.section}>
        <Navbar activeLink={'Add Hotel'}/>
        <CreateHotelContent/>
    </section>
    </>
  )
}

export default User
