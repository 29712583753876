import React from 'react'
import Navbar from '../components/VendorNavbar'
import {EditHotelContent} from '../components/EditHotelContent'
import classes from './user.module.css'

const EditHotel = () => {
  return (
    <>
    <section className={classes.section}>
        <Navbar activeLink={''}/>
        <EditHotelContent/>
    </section>
    </>
  )
}

export default EditHotel
