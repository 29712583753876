import {useState, useEffect} from 'react'
import {
  Card,
  Image,
  Text,
  Group,
  SimpleGrid
} from '@mantine/core';
import classes from './Carousel.module.css';
import { useGetTop5HotelsMutation } from '../slices/usersApiSlice';

export function TopHotels() {
  const [hotels] = useGetTop5HotelsMutation()
  const [data, setData] = useState([])

  const getHotels = async()=>{
      try {
        const response = await hotels().unwrap()
          setData(response)
      } catch (error) {
        console.log(error)
      //   toast.error(error?.data?.message || error.error) 
      }
    }
  
  
    useEffect(()=>{
     getHotels()

     // eslint-disable-next-line
    }, [])

    const Display = ()=>{
      const hotels = data.map((item)=>{
          const {banner, address, title, price, _id } = item
          return (
              <Card withBorder radius="md" className={classes.card} key={_id}>
              <Card.Section>
                  <a href={`/hotel/id/${_id}`}>
                      <Image src={banner} height={240} />
                  </a>
              </Card.Section>
  
              <a href={`/hotel/id/${_id}`} className={classes.title} fw={500}>
                  {title}
              </a>
              
              <Text fz="sm" className={classes.address}>
                  {address}
              </Text>
              <Group justify="space-between" className={classes.footer}>
                  <Text inline className={classes.price}>
                      starting from <span>#{price}</span>
                  </Text>   
              </Group>
  
  </Card>
          )
      })
        return hotels
    }

  return (
    <section className={classes.container}>
      <h1>Top Hotels</h1>
      <p>Best hotels around the country specially curated to suit your taste.</p>

      <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }} spacing={{ base: 10, sm: 'xl' }} verticalSpacing={{ base: 'md', sm: 'xl' }} className={classes.section}>
        {<Display/>}
    </SimpleGrid>
    </section>
  );
}