import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Navbar from '../features/header/Navbar';
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from "@mantine/core";
import classes from "./Login.module.css";
import { useLoginMutation } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [login, {isLoading}] = useLoginMutation()
  const {userInfo} = useSelector((state)=>state.auth)

  const handleNavigation = (url)=>{
    navigate(url)
  }

  useEffect(()=>{
    if(userInfo){
      navigate('/')
    }
  }, [navigate, userInfo])
 

  const handleSubmit = async()=>{
    try {
        const response = await login({email, password}).unwrap();
        dispatch(setCredentials({...response}))
        handleNavigation('/')
    } catch (error) {
      toast.error(error?.data?.message || error.error) 
    }
  }
  return (
    <>
      <Navbar />
      <ToastContainer/>
      <Container size={420} my={40}>
        <Title ta="center" className={classes.title}>
          Welcome back!
        </Title>
        <Text c="dimmed" size="sm" ta="center" mt={5}>
          Do not have an account yet?{" "}
          <Anchor onClick={()=>handleNavigation('/register')}  size="sm" component="button">
            Create account
          </Anchor>
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput label="Email" placeholder="you@email.dev" onChange={(e)=>setEmail(e.target.value)} required />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            onChange={(e)=>setPassword(e.target.value)}
            mt="md"
          />
          <Group justify="space-between" mt="lg">
            <Checkbox label="Remember me" />
            <Anchor component="button" size="sm">
              Forgot password?
            </Anchor>
          </Group>
          <Button onClick={()=> handleSubmit()} fullWidth mt="xl" disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Sign in'}
          </Button>
        </Paper>
      </Container>
    </>
  );
}

export default Login
