import React, { useState, useEffect } from "react";
import { IconEdit, IconTrash, IconPlus } from "@tabler/icons-react";
import {
  SimpleGrid,
  Divider,
  Text,
  Image,
  Group,
  Button,
  Flex,
  Pagination,
  Loader,
  Modal,
  TextInput,
  MultiSelect,
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import classes from "../components/rooms.module.css";
import {
  useGetLocationsMutation,
  useDeleteLocationMutation,
  useCreateLocationMutation,
  useGetLocationMutation,
  useUpdateLocationMutation,
} from "../slices/usersApiSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";

const LocationContent = () => {
  const [total, setTotal] = useState(0);
  const [totalLocations, setTotalLocations] = useState(0);
  const [getlocation, { isLoading }] = useGetLocationsMutation();
  const [create_location, { isLoading: isLocationLoading }] =
    useCreateLocationMutation();
  const [delete_location, { isLoading: isDeleteLocationLoading }] =
    useDeleteLocationMutation();
  const [single_location] = useGetLocationMutation();
  const [update_location, { isLoading: isUpdateLocationLoading }] =
    useUpdateLocationMutation();
  const [opened, { open, close }] = useDisclosure(false);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [value, setValue] = useState([]);
  const [image, setImage] = useState([]);
  // const [location, setLocation] = useState('')
  const [openedupdatelocation, { open: openU, close: closeU }] =
    useDisclosure(false);
  const [currentLocationImage, setCurrentLocationImage] = useState("");
  const [features, setFeatures] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [newImage, setNewImage] = useState([]);
  let nPage;

  //previews the banner image
  const previews = image.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        src={imageUrl}
        onLoad={() => URL.revokeObjectURL(imageUrl)}
        className={classes.preview}
      />
    );
  });

  const secondPreview = newImage.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        src={imageUrl}
        onLoad={() => URL.revokeObjectURL(imageUrl)}
        className={classes.preview}
      />
    );
  });

  const getLocations = async (page) => {
    try {
      const response = await getlocation(page).unwrap();
      setTotal(response.total);
      setTotalLocations(response.totalLocation);
      const room = response.locations.map((item) => {
        const { location, features, locationImage, _id } = item;
        return (
          <div className={classes.card} key={_id}>
            <div className={classes.item}>
              <div className={classes.imgContainer}>
                <a href="/">
                  <Image h={220} src={locationImage} className={classes.img} />
                </a>
              </div>
              <div className={classes.content}>
                <div size="sm" className={classes.textContainer}>
                  <Text fw={700} className={classes.text}>
                    Location:
                  </Text>
                  {location}
                </div>
                <div size="sm" className={classes.textContainer}>
                  <Text fw={700} className={classes.text}>
                    Features:
                  </Text>
                  <span>
                    {features.map((feature, index) => (
                      <span key={index}>
                        {feature}
                        {index < features.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </span>
                </div>
              </div>
            </div>
            <Group justify="center" p={15}>
              <Button
                size="xs"
                color="green"
                leftSection={<IconEdit size={14} />}
                variant="filled"
                onClick={() => getLocation(_id)}
              >
                Edit
              </Button>
              <Button
                size="xs"
                color="red"
                leftSection={<IconTrash size={14} />}
                variant="filled"
                disabled={isDeleteLocationLoading}
                loading={isDeleteLocationLoading}
                onClick={() => deleteHotel(_id)}
              >
                Delete
              </Button>
            </Group>
          </div>
        );
      });

      const nPages = Math.ceil(response.totalLocation / 2);
      setTotalPages(nPages);
      setData(room);
      return;
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };
  const deleteHotel = async (id) => {
    try {
      const response = await delete_location(id).unwrap();
      nPage = 1;
      getLocations(nPage);
      toast.success(response.message);
      return response;
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const getLocation = async (id) => {
    openU();
    try {
      const response = await single_location(id).unwrap();
      const data = {
        location: response.location,
      };
      // toast.success(response.message);
      setFeatures(response.features);
      updateLocationForm.setValues(data);
      setLocationId(response._id);
      setCurrentLocationImage(response.locationImage);
      return response;
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  useEffect(() => {
    getLocations(nPage);
    // eslint-disable-next-line
  }, []);

  const setPagination = (value) => {
    nPage = value;
    getLocations(nPage);
  };

  const form = useForm({
    initialValues: {
      location: "",
      features: value,
      locationImage: image,
    },
  });

  const updateLocationForm = useForm({
    initialValues: {
      location: "",
      features: value,
      locationImage: image,
    },
  });

  const handleImageDrop = (acceptedFiles) => {
    setImage(acceptedFiles);
    form.setValues((prevValues) => ({
      ...prevValues,
      locationImage: acceptedFiles[0],
    }));
  };
  const handleUpdateImageDrop = (acceptedFiles) => {
    setNewImage(acceptedFiles);
    updateLocationForm.setValues((prevValues) => ({
      ...prevValues,
      locationImage: acceptedFiles[0],
    }));
  };

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("location", values.location);

      // Append features individually
      value.forEach((feature, index) => {
        formData.append("features", feature);
      });
      formData.append("locationImage", values.locationImage);

      const response = await create_location(formData);
      // Check for specific success conditions
      if (response.data?.status !== 201) {
        toast.error(response.error.data.message || response.error);
      } else {
        // Handle unexpected success response status
        toast.success("Location created successfully");
        getLocations(1);
        close();
      }
    } catch (error) {
      // Exception: Display error toast
      toast.error(error?.data?.message || error.error);
    }
  };

  const handleUpdateSubmit = async (values) => {
    console.log(values);
    try {
      const formData = new FormData();
      formData.append("location", values.location);

      // Append features individually
      features.forEach((feature, index) => {
        formData.append("features", feature);
      });
      if (values.locationImage.length !== 0) {
        formData.append("locationImage", values.locationImage);
      }
      formData.append("id", locationId);

      const response = await update_location(formData);
      console.log(response)
      // Check for specific success conditions
      if (response.data?.status !== 200) {
        toast.error(response.error.data.message || response.error);
      } else {
        // Handle unexpected success response status
        toast.success("Location updated successfully");
        getLocations(1);
        close();
      }
    } catch (error) {
      // Exception: Display error toast
      toast.error(error?.data?.message || error.error);
    }
  };

  return (
    <section className={classes.section}>
      <ToastContainer />

      <Modal
        opened={openedupdatelocation}
        onClose={closeU}
        title="Update Location"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        zIndex={500}
      >
        <form
          onSubmit={updateLocationForm.onSubmit((values) => {
            handleUpdateSubmit(values);
          })}
        >
          <TextInput
            label="Location"
            description="Enter a location"
            mb={10}
            {...updateLocationForm.getInputProps("location")}
            required
          />

          <MultiSelect
            label="Features"
            description="Select features that is suitable for the provided location"
            {...updateLocationForm.getInputProps("features")}
            data={[
              "Mountain",
              "Safari",
              "Beach",
              "Snow",
              "Water parks",
              "City center",
              "Lakes",
              "Rivers",
              "Palm trees",
              "Deserts",
              "Conservatories",
              "Monuments",
              "Castles",
              "Heritage sites",
              "Blue Sky",
              "Waterfall",
              "Chinese foods",
              "Caves",
              "Museum",
              "Art Gallary",
              "Tall buildings",
              "Zoo",
              "Forest",
            ]}
            styles={{
              dropdown: { zIndex: 900 },
            }}
            mb={10}
            searchable
            clearable
            nothingFoundMessage="Location not available..."
            hidePickedOptions
            maxDropdownHeight={200}
            withAsterisk
            value={features}
            onChange={setFeatures}
          />

          <Dropzone
            accept={IMAGE_MIME_TYPE}
            onDrop={handleUpdateImageDrop}
            className={classes.dropzone}
            maxFiles={1}
            {...updateLocationForm.getInputProps("locationImage")}
            onReject={() => toast.error("Maximum file exceeded.")}
          >
            <Text size="sm" c="dimmed" ta="center">
              Choose Location Image. (1 image only)
            </Text>
          </Dropzone>
          <SimpleGrid
            cols={{ base: 1, sm: 2 }}
            mt={secondPreview.length > 0 ? "md" : 0}
          >
            <div>
              <Text fz="sm">Current Location Image</Text>
              <Image h={200} src={currentLocationImage} />
            </div>
            <div>
              <Text fz="sm">New Location Image</Text>
              {secondPreview}
            </div>
          </SimpleGrid>
          <Flex justify="flex-end">
            <Button
              type="submit"
              mt={10}
              disabled={isUpdateLocationLoading}
              loading={isUpdateLocationLoading}
              //   loaderProps={{ type: "dots" }}
            >
              Update location
            </Button>
          </Flex>
        </form>
      </Modal>

      {/* first modal start */}
      <Modal
        opened={opened}
        onClose={close}
        title="Create new Location"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        zIndex={500}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            handleSubmit(values);
          })}
        >
          <TextInput
            label="Location"
            description="Enter a location"
            mb={10}
            {...form.getInputProps("location")}
            required
          />

          <MultiSelect
            label="Features"
            description="Select features that is suitable for the provided location"
            {...form.getInputProps("features")}
            data={[
              "Mountain",
              "Safari",
              "Beach",
              "Snow",
              "Water parks",
              "City center",
              "Lakes",
              "Rivers",
              "Palm trees",
              "Deserts",
              "Conservatories",
              "Monuments",
              "Castles",
              "Heritage sites",
              "Blue Sky",
              "Waterfall",
              "Chinese foods",
              "Caves",
              "Museum",
              "Art Gallary",
              "Tall buildings",
              "Zoo",
              "Forest",
            ]}
            styles={{
              dropdown: { zIndex: 900 },
            }}
            mb={10}
            searchable
            clearable
            nothingFoundMessage="Feature not available..."
            hidePickedOptions
            maxDropdownHeight={200}
            withAsterisk
            value={value}
            onChange={setValue}
          />

          <Dropzone
            accept={IMAGE_MIME_TYPE}
            onDrop={handleImageDrop}
            className={classes.dropzone}
            maxFiles={1}
            {...form.getInputProps("locationImage")}
            onReject={() => toast.error("Maximum file exceeded.")}
          >
            <Text size="sm" c="dimmed" ta="center">
              Choose Location Image. (1 image only)
            </Text>
          </Dropzone>
          <SimpleGrid
            cols={{ base: 1, sm: 4 }}
            mt={previews.length > 0 ? "xl" : 0}
          >
            {previews}
          </SimpleGrid>
          <Flex justify="flex-end">
            <Button
              type="submit"
              mt={10}
              disabled={isLocationLoading}
              loading={isLocationLoading}
              //   loaderProps={{ type: "dots" }}
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Modal>
      <Flex
        justify={{ sm: "space-between" }}
        align="center"
        px={15}
        direction={{ base: "column", sm: "row" }}
      >
        <h2 className={classes.heading}>Manage Locations</h2>

        <Group justify="flex-start">
          <Button
            leftSection={<IconPlus size={14} />}
            variant="filled"
            color="rgba(143, 189, 79, 1)"
            onClick={open}
          >
            Add Location
          </Button>
          {/* <Button leftSection={<IconArrowBack size={14} />} variant="filled" color= 'cyan' onClick={()=>navigate(`/hotel/edit/${id}`)}>
        Back to hotels
      </Button> */}
        </Group>
      </Flex>
      <Divider />
      <Text>
        Showing result for{" "}
        <span>
          {total} of {totalLocations}
        </span>{" "}
        locations
      </Text>

      <SimpleGrid
        cols={{ base: 1, sm: 1, lg: 1 }}
        spacing={{ base: 10, sm: "xl" }}
        verticalSpacing={{ base: "md", sm: "xl" }}
      >
        <Pagination total={totalPages} onChange={setPagination} />
        {isLoading ? (
          <Flex justify="center" w="20vw" h="20vh" align="center">
            <Loader color="blue" size={50} />
          </Flex>
        ) : (
          data
        )}
      </SimpleGrid>
    </section>
  );
};

export default LocationContent;
