import React, {useEffect, useState} from 'react'
import classes from './profile.module.css'
import {
    TextInput,
    Divider,
    Text
  } from "@mantine/core";
  import {
    IconInfoCircleFilled
  } from '@tabler/icons-react';
import { useUpdateProfileMutation, useGetUserMutation } from "../slices/usersApiSlice";
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch } from 'react-redux';
import { setCredentials } from '../slices/authSlice';
import {Link} from 'react-router-dom'

export const Profile = () => {
  const dispatch = useDispatch()
  const [user] = useGetUserMutation()
  const [updateProfile, {isLoading}] = useUpdateProfileMutation()
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [firstName, setFirstName] =  useState('')
  const [lastName, setLastName] =  useState('')
  const [country, setCountry] =  useState('')
  const [address, setAddress] =  useState('')
  const [city, setCity] =  useState('')
  const [state, setState] =  useState('')

  const handleUpdateProfile = async(req, res)=>{
    try {
      const response = await updateProfile({email, name, firstName, lastName, country, address, city, state, phone}).unwrap()
      const userInfo = {
        id: response.data._id,
        name: response.data.name,
        role: response.data.role,
        vendorStatus: response.data.vendorStatus,
        token: response.token
      }
      dispatch(setCredentials(userInfo))
      getUser()
      toast.success(response.msg)
    } catch (error) {
      toast.error(error?.data?.message || error.error) 
    }
  }

  const getUser = async()=>{
    try {
      const response = await user().unwrap()
      const {email, name, phone, firstName, lastName, country, address, state, city} = response.data
      setEmail(email)
      setName(name)
      setPhone(phone)
      setFirstName(firstName)
      setLastName(lastName)
      setCountry(country)
      setAddress(address)
      setState(state)
      setCity(city)

    } catch (error) {
      toast.error(error?.data?.message || error.error) 
    }
  }


  useEffect(()=>{
   getUser()
   // eslint-disable-next-line
  }, [])

  

  return (
    <section className={classes.section}>
      <ToastContainer/>
       <h2 className={classes.heading}>My Profile</h2>
       <Divider/>
       <div className={classes.grid}>
        <div>
            <h4>Personal Details</h4>
            <TextInput label="Full Name" placeholder="John Doe" className={classes.name} defaultValue={name} onChange={(e)=>setName(e.target.value)}/>
            <TextInput label="Email" placeholder="john@email.com" defaultValue={email}className={classes.name} readOnly={true} onChange={(e)=>setEmail(e.target.value)}/>
            <div className={classes.info}><IconInfoCircleFilled className={classes.icon}/><Text size='sm'>By default, we do not allow the change of email. If you need a change of email, <Link to='/'>contact us</Link>.</Text></div>
            <div className={classes.innerGrid}>
                <TextInput label="First Name" placeholder="John" defaultValue={firstName || ''} className={classes.name} onChange={(e)=>setFirstName(e.target.value)}/>
                <TextInput label="Last Name" placeholder="Doe" defaultValue={lastName || ''} className={classes.name} onChange={(e)=>setLastName(e.target.value)}/>
            </div>
            <TextInput label="Phone Number" placeholder="08123456789" defaultValue={phone} className={classes.name} onChange={(e)=>setPhone(e.target.value)}/>
        </div>

        <div>
            <h4>Location Details</h4>
            <TextInput label="Address" placeholder="Address" defaultValue={address} className={classes.name} onChange={(e)=>setAddress(e.target.value)}/>
            <TextInput label="City" placeholder="City" defaultValue={city} className={classes.name} onChange={(e)=>setCity(e.target.value)}/>
            <TextInput label="State" placeholder="State" defaultValue={state} className={classes.name} onChange={(e)=>setState(e.target.value)}/>
            <TextInput label="Country" placeholder="Country" defaultValue={country} className={classes.name} onChange={(e)=>setCountry(e.target.value)}/>
        </div>
       </div>
       <Divider/>

       <button className={classes.btn} onClick={()=>handleUpdateProfile()} disabled={isLoading}>{isLoading ? 'Loading...' : 'Update Profile'}</button>
    </section>
  )
}