import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import Navbar from '../features/header/Navbar';
import {
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Button,
} from "@mantine/core";
import classes from "./Login.module.css";
import { useRegisterMutation } from "../slices/usersApiSlice";
import { setCredentials } from '../slices/authSlice';
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Register = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')

  const [register, {isLoading}] = useRegisterMutation()
  const {userInfo} = useSelector((state)=>state.auth)

  useEffect(()=>{
    if(userInfo){
      navigate('/')
    }
  }, [navigate, userInfo])

  const handleNavigation = (url)=>{
    navigate(url)
  }

  const handleSubmit = async()=>{
    try {
      const response = await register({firstName, lastName, email, phone, password}).unwrap()
      dispatch(setCredentials({...response}))
      handleNavigation('/')
    } catch (error) {
      toast.error(error?.data?.message || error.error) 
    }
  }
   
  return (
    <>
      <Navbar />
      <ToastContainer/>
       <Container size={420} my={40}>
        <Title ta="center" className={classes.title}>
          Welcome Fav!
        </Title>
        <Text c="dimmed" size="sm" ta="center" mt={5}>
          Already have an account?{" "}
          <Anchor onClick={() => handleNavigation('/login')}  size="sm" component="button">
            Sign In
          </Anchor>
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput label="First Name" placeholder="John" onChange={(e)=>setFirstName(e.target.value)} required/>
          <TextInput label="Last Name" placeholder="Doe" onChange={(e)=>setLastName(e.target.value)} required mt="sm"/>
          <TextInput label="Email" placeholder="john@email.dev" onChange={(e)=>setEmail(e.target.value)} required mt="sm"/>
          <TextInput label="Phone Number" placeholder="08123456789" onChange={(e)=>setPhone(e.target.value)} required mt="sm"/>
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            onChange={(e)=>setPassword(e.target.value)}
            mt="sm"
          />
          
          <Button  fullWidth mt="xl" onClick={()=>handleSubmit()}  disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Create Your Account'}
          </Button>
        </Paper>
      </Container>
    </>
  );
};

export default Register;
