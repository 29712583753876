import React, {useState, useEffect} from 'react'
import { IconEye, IconTrash, IconEdit } from '@tabler/icons-react';
import {
    SimpleGrid,
    Divider,
    Card,
    Text, 
    Image,
    Group,
    Button,
    Flex,
    Pagination,
    Loader,
} from "@mantine/core";
import classes from './myhotel.module.css';
import {useGetAllHotelsMutation, useDeleteHotelMutation} from "../slices/usersApiSlice";
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom';

const AllHotelsContent = () => {
    const [total, setTotal] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [hotels, {isLoading}] = useGetAllHotelsMutation();
    const [delete_Hotel] = useDeleteHotelMutation()
    const [data, setData] = useState([])
    const navigate = useNavigate()
    let nPage;

//  return <Pagination  total={10} />;
    const getHotels = async(page)=>{
        try {
          const response = await hotels(page).unwrap()
          if(response.length === 0){
            return 'No hotels was found'
        }
        setTotal(response.total)
        const hotel = response.data.map((item)=>{
            const {banner, address, title, price, _id } = item
            return (
                <Card withBorder radius="md" className={classes.card} key={_id}>
                <Card.Section>
                    <a href='/'>
                        <Image src={banner} height={240} />
                    </a>
                </Card.Section>
    
                <a href='/hotel' className={classes.title} fw={500}>
                    {title}
                </a>
                
                <Text fz="sm" className={classes.address}>
                    {address}
                </Text>
                <Group justify="space-between" className={classes.footer}>
                    <Text inline className={classes.price}>
                        starting from <span>#{price}</span>
                    </Text>   
                </Group>
                <Flex mih={50} gap="md" justify="center" align="center" direction="row" wrap="wrap" >
                    <Button size='xs' color='green' leftSection={<IconEdit size={14} />} variant="filled" onClick={()=>navigate(`/hotel/edit/${_id}`)}>Edit</Button>
                    <Button size='xs' leftSection={<IconEye size={14} />} variant="filled">View</Button>
                    <Button size='xs' color="red" leftSection={<IconTrash size={14} />} variant="filled" onClick={()=>deleteHotel(_id)}>Delete</Button>
               
            </Flex>
    </Card>
            )
        })
            setData(hotel)
            const nPages = Math.ceil(response.totalHotels / 3);
        setTotalPages(nPages)
          return hotel
        } catch (error) {
            toast.error(error?.data?.message || error.error)
        }
      }
      const deleteHotel = async(id)=>{
        try {
            const response = await delete_Hotel(id).unwrap()
            getHotels()
            toast.success(response.message)
            return response
        } catch (error) {
      toast.error(error?.data?.message || error.error) 
        }
      }
    
    
      useEffect(()=>{
       getHotels(nPage)
       // eslint-disable-next-line
      }, [])
    const setPagination = (value) => {
        nPage = value
        getHotels(nPage)
    }

  return (
    <section className={classes.section}>
      <ToastContainer />
      <h2 className={classes.heading}>All Hotels</h2>
      <Pagination total={totalPages} onChange={setPagination} />

      <Divider />
      <Text>
              Show result for <span>{total}</span> hotels
      </Text>

      <SimpleGrid
        cols={{ base: 1, sm: 2, lg: 3 }}
        spacing={{ base: 10, sm: "xl" }}
        verticalSpacing={{ base: "md", sm: "xl" }}
      >
        {isLoading ?<Flex justify="center" w='20vw' h='20vh' align="center"><Loader color="blue" size={50} /></Flex> : data}
      </SimpleGrid>
    </section>
  );
}

export { AllHotelsContent };