import { Button, Flex, Input, Menu } from "@mantine/core";
import Picker from "./Picker";

function Demo() {
  return (
    <>
      <Flex
        direction={{ base: "column", sm: "row" }}
        gap={{ base: "sm", sm: "lg" }}
        justify={{ sm: "center" }}
      >
        {/* <Input placeholder="Start - End" /> */}
        <Picker />
        {/* <Input placeholder="Guest" /> */}
        <Menu width={190}>
          <Menu.Target>
            <Input placeholder="Guest" />
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item>
              Adults - 1 + <br />
              Children - 1 + <br />
            </Menu.Item>

            {/* Other items ... */}
          </Menu.Dropdown>
        </Menu>
        <Button variant="filled">Check Available</Button>
      </Flex>
    </>
  );
}

export default Demo;
